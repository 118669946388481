// AUTH
export const AUTH_LOGIN_STATUS = 'AUTH_LOGIN_STATUS';
export const AUTH_LOGIN_TOKEN = 'AUTH_LOGIN_TOKEN';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_USER_MOBILE = 'AUTH_USER_MOBILE';

export const AUTH_SIGNUP_STATUS = 'AUTH_SIGNUP_STATUS';
export const AUTH_SIGNUP_TOKEN = 'AUTH_SIGNUP_TOKEN';
export const AUTH_SIGNUP_ERROR = 'AUTH_SIGNUP_ERROR';

export const AUTH_SEND_OTP_STATUS = 'AUTH_SEND_OTP_STATUS';
export const AUTH_SEND_OTP_ERROR = 'AUTH_SEND_OTP_ERROR';

export const AUTH_RESEND_OTP_STATUS = 'AUTH_RESEND_OTP_STATUS';
export const AUTH_RESEND_OTP_ERROR = 'AUTH_RESEND_OTP_ERROR';

export const AUTH_VERIFY_OTP_STATUS = 'AUTH_VERIFY_OTP_STATUS';
export const AUTH_VERIFY_OTP_ERROR = 'AUTH_VERIFY_OTP_ERROR';

export const AUTH_RESETPASSWORD_KEY = 'AUTH_RESETPASSWORD_KEY';

// export const AUTH_SIGNUP_USER = "AUTH_SIGNUP_USER";
export const AUTH_FORGOTPASSWORD_STATUS = 'AUTH_FORGOTPASSWORD_STAT US';
export const AUTH_RESETPASSWORD_STATUS = 'AUTH_RESETPASSWORD_STATUS';
// export const AUTH_RESETPASSWORD_KEY = 'AUTH_RESETPASSWORD_KEY';
export const AUTH_RESETPASSWORD_ERROR = 'AUTH_RESETPASSWORD_ERROR';
export const AUTH_USER_ID = 'AUTH_USER_ID';

// USER
export const USER_STATES = 'USER_STATES';
export const USER_INFO = 'USER_INFO';
export const USER_INFO_UPDATE = 'USER_INFO_UPDATE';
export const USER_INFO_STATUS = 'USER_INFO_STATUS';
export const USER_INFO_UPDATE_STATUS = 'USER_INFO_UPDATE_STATUS';
export const USER_INFO_STATE = 'USER_INFO_STATE';
export const USER_INFO_STATE_LIST = 'USER_INFO_STATE_LIST';
export const USER_INFO_STATE_FEATURES = 'USER_INFO_STATE_FEATURES';
export const USER_INFO_STATE_SIDEBAR = 'USER_INFO_STATE_SIDEBAR';
export const USER_INFO_STATE_ROUTES = 'USER_INFO_STATE_ROUTES';

// FEATURE MANAGEMENT
export const FEATURE_MANAGEMENT_FEATURES = 'FEATURE_MANAGEMENT_FEATURES';
export const FEATURE_MANAGEMENT_USER_PERMISSIONS = 'FEATURE_MANAGEMENT_USER_PERMISSIONS';
// FORMIO

export const FORMIO_FORM_CREATE_STSTUS = 'FORMIO_FORM_CREATE_STSTUS';
export const FORMIO_FORM_CREATE = 'FORMIO_FORM_CREATE';
export const FORMIO_FORM_LIST_STATUS = 'FORMIO_FORM_LIST_STATUS';
export const FORMIO_FORM_LIST = 'FORMIO_FORM_LIST';
//
export const FORMIO_FORM = 'FORMIO_FORM';
// export const FORMIO_FORM_LIST = 'FORMIO_FORM_LIST';
export const FORMIO_ADDL_FORM_DATA = 'FORMIO_ADDL_FORM_DATA';
export const FORMIO_ADDL_FORM_DATA_LIST = 'FORMIO_ADDL_FORM_DATA_LIST';
export const FORMIO_SUBMISSION = 'FORMIO_SUBMISSION';
export const FORMIO_SUBMISSION_LIST = 'FORMIO_SUBMISSION_LIST';
export const FORMIO_MESSAGE = 'FORMIO_MESSAGE';
export const FORMIO_MESSAGE_TYPE = 'FORMIO_MESSAGE_TYPE';
export const FORMIO_NEWSUBMISSION_STATUS = 'FORMIO_NEWSUBMISSION_STATUS';
export const FORMIO_NEWSUBMISSION = 'FORMIO_NEWSUBMISSION';
// export const FORMIO_FORM_LIST_STATUS = 'FORMIO_FORM_LIST_STATUS';
export const FORMIO_SYNCAPI_STATUS = 'FORMIO_SYNCAPI_STATUS';
export const FORMIO_SYNCAPI = 'FORMIO_SYNCAPI';
export const FORMIO_DATADOWN_STATUS = 'FORMIO_DATADOWN_STATUS';
export const FORMIO_DATADOWN = 'FORMIO_DATADOWN';
export const FORMIO_PERSISTENCE_EXISTENCE = 'FORMIO_PERSISTENCE_EXISTENCE';
// user management feature permission
export const USERMANAGEMENT_USERS = 'USERMANAGEMENT_USERS';
export const USERMANAGEMENT_USERS_STATUS = 'USERMANAGEMENT_USERS_STATUS';
export const USERMANAGEMENT_PERMISSIONS = 'USERMANAGEMENT_PERMISSIONS';
export const USERMANAGEMENT_PERMISSIONS_STATUS = 'USERMANAGEMENT_PERMISSIONS_STATUS';
export const USERMANAGEMENT_STATES = 'USERMANAGEMENT_STATES';
export const USERMANAGEMENT_STATES_STATUS = 'USERMANAGEMENT_STATES_STATUS';
export const USERMANAGEMENT_FEATURES = 'USERMANAGEMENT_FEATURES';
export const USERMANAGEMENT_FEATURES_STATUS = 'USERMANAGEMENT_FEATURES_STATUS';
export const USERMANAGEMENT_SEARCHSTATE = 'USERMANAGEMENT_SEARCHSTATE';
export const USERMANAGEMENT_SEARCHSTATE_STATUS = 'USERMANAGEMENT_SEARCHSTATE_STATUS';
export const USERMANAGEMENT_ADDPERMISSION = 'USERMANAGEMENT_ADDPERMISSION';
export const USERMANAGEMENT_ADDPERMISSION_STATUS = 'USERMANAGEMENT_ADDPERMISSION_STATUS';
export const USERMANAGEMENT_UPDATEPERMISSION = 'USERMANAGEMENT_UPDATEPERMISSION';
export const USERMANAGEMENT_UPDATEPERMISSION_STATUS = 'USERMANAGEMENT_UPDATEPERMISSION_STATUS';

export const CALLERMANAGEMENT_CALLERS = 'CALLERMANAGEMENT_CALLERS';
export const CALLERMANAGEMENT_CALLERS_STATUS = 'CALLERMANAGEMENT_CALLERS_STATUS';
export const CALLERMANAGEMENT_USERSTATE = 'CALLERMANAGEMENT_USERSTATE';
export const CALLERMANAGEMENT_USERSTATE_STATUS = 'CALLERMANAGEMENT_USERSTATE_STATUS';
export const CALLERMANAGEMENT_USERPROFILE_STATUS = 'CALLERMANAGEMENT_USERPROFILE_STATUS';
export const CALLERMANAGEMENT_USERPROFILE = 'CALLERMANAGEMENT_USERPROFILE';
export const USERMANAGEMENT_USER_PERMISSIONS = 'USERMANAGEMENT_USER_PERMISSIONS';

export const TASKMANAGEMENT_ADDTYPE = 'TASKMANAGEMENT_ADDTYPE';
export const TASKMANAGEMENT_ADDTYPE_STATUS = 'TASKMANAGEMENT_ADDTYPE_STATUS';
export const TASKMANAGEMENT_ALLTYPE = 'TASKMANAGEMENT_ALLTYPE';
export const TASKMANAGEMENT_ALLTYPE_STATUS = 'TASKMANAGEMENT_ALLTYPE_STATUS';
export const TASKMANAGEMENT_ADDSTATUS = 'TASKMANAGEMENT_ADDSTATUS';
export const TASKMANAGEMENT_ADDSTATUS_STATUS = 'TASKMANAGEMENT_ADDSTATUS_STATUS';
export const TASKMANAGEMENT_ALLSTATUS = 'TASKMANAGEMENT_ALLSTATUS';
export const TASKMANAGEMENT_ALLSTATUS_STATUS = 'TASKMANAGEMENT_ALLSTATUS_STATUS';
export const TASKMANAGEMENT_ALLCYCLE = 'TASKMANAGEMENT_ALLCYCLE';
export const TASKMANAGEMENT_ALLCYCLE_STATUS = 'TASKMANAGEMENT_ALLCYCLE_STATUS';
export const TASKMANAGEMENT_ADDMESSAGE = ' TASKMANAGEMENT_ADDMESSAGE';
export const TASKMANAGEMENT_ADDMESSAGE_STATUS = 'TASKMANAGEMENT_ADDMESSAGE_STATUS';
export const TASKMANAGEMENT_ALLMESSAGE = 'TASKMANAGEMENT_ALLMESSAGE';
export const TASKMANAGEMENT_ALLMESSAGE_STATUS = 'TASKMANAGEMENT_ALLMESSAGE_STATUS';
export const TASKMANAGEMENT_ALLCALLERS = 'TASKMANAGEMENT_ALLCALLERS';
export const TASKMANAGEMENT_ALLCALLERS_STATUS = 'TASKMANAGEMENT_ALLCALLERS_STATUS';

export const TASKASSIGNMENT_ALLTASK = 'TASKASSIGNMENT_ALLTASK';
export const TASKASSIGNMENT_ALLTASK_STATUS = 'TASKASSIGNMENT_ALLTASK_STATUS';
export const TASKASSIGNMENT_ALLTASKNOTDONE = 'TASKASSIGNMENT_ALLTASKNOTDONE';
export const TASKASSIGNMENT_ADDTASK = 'TASKASSIGNMENT_ADDTASK';
export const TASKASSIGNMENT_ADDTASK_STATUS = 'TASKASSIGNMENT_ADDTASK_STATUS';
export const TASKASSIGNMENT_STATE = 'TASKASSIGNMENT_STATE';
export const TASKASSIGNMENT_UPDATETASK = 'TASKASSIGNMENT_UPDATETASK';
export const TASKASSIGNMENT_UPDATETASK_STATUS = 'TASKASSIGNMENT_UPDATETASK_STATUS';
export const TASKASSIGNMENT_SINGLEFORM = 'TASKASSIGNMENT_SINGLEFORM';
export const TASKASSIGNMENT_SINGLEFORM_STATUS = 'TASKASSIGNMENT_SINGLEFORM_STATUS';
export const TASKASSIGNMENT_SINGLESUBMISSION = 'TASKASSIGNMENT_SINGLESUBMISSION';
export const TASKASSIGNMENT_SINGLESUBMISSION_STATUS = 'TASKASSIGNMENT_SINGLESUBMISSION_STATUS';
export const TASKASSIGNMENT_PSINGLESUBMISSION = 'TASKASSIGNMENT_PSINGLESUBMISSION';
export const TASKASSIGNMENT_PSINGLESUBMISSION_STATUS = 'TASKASSIGNMENT_PSINGLESUBMISSION_STATUS';
export const TASKASSIGNMENT_FORMDETS = 'TASKASSIGNMENT_FORMDETS';
export const TASKASSIGNMENT_FORMDETS_STATUS = 'TASKASSIGNMENT_FORMDETS_STATUS';
export const TASKASSIGNMENT_BULKASSIGN = 'TASKASSIGNMENT_BULKASSIGN';
export const TASKASSIGNMENT_BULKASSIGN_STATUS = 'TASKASSIGNMENT_BULKASSIGN_STATUS';
export const TASKASSIGNMENT_BULKUNDO = 'TASKASSIGNMENT_BULKUNDO';
export const TASKASSIGNMENT_TASKSEARCH_STATUS = 'TASKASSIGNMENT_TASKSEARCH_STATUS';
export const TASKASSIGNMENT_TASKSEARCH = 'TASKASSIGNMENT_TASKSEARCH';
export const TASKASSIGNMENT_ALLTEMPLATE_STATUS = 'TASKASSIGNMENT_ALLTEMPLATE_STATUS';
export const TASKASSIGNMENT_ALLTEMPLATE = 'TASKASSIGNMENT_ALLTEMPLATE';
export const TASKASSIGNMENT_SENDTEMPLATE_STATUS = 'TASKASSIGNMENT_SENDTEMPLATE_STATUS';
export const TASKASSIGNMENT_SENDTEMPLATE = 'TASKASSIGNMENT_SENDTEMPLATE';
export const TASKASSIGNMENT_PERSISTENTFORM_STATUS = 'TASKASSIGNMENT_PERSISTENTFORM_STATUS';
export const TASKASSIGNMENT_PERSISTENTFORM = 'TASKASSIGNMENT_PERSISTENTFORM';
export const TASKASSIGNMENT_MYTASK = 'TASKASSIGNMENT_MYTASK';
export const TASKASSIGNMENT_MYTASK_STATUS = 'TASKASSIGNMENT_MYTASK_STATUS';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const STATE_TASKCOUNT_STATUS = 'STATE_TASKCOUNT_STATUS';
export const STATE_TASKCOUNT = 'STATE_TASKCOUNT';
export const ALLSTATE_TASKCOUNT_STATUS = 'ALLSTATE_TASKCOUNT_STATUS';
export const ALLSTATE_TASKCOUNT = 'ALLSTATE_TASKCOUNT';
export const CALLER_TASKCOUNT_STATUS = 'CALLER_TASKCOUNT_STATUS';
export const CALLER_TASKCOUNT = 'CALLER_TASKCOUNT';
export const BULKUPLOAD_MESSAGE = 'BULKUPLOAD_MESSAGE';
export const BULKUPLOAD_STATUS = 'BULKUPLOAD_STATUS';

export const REPORT_GENERATOR_STATUS = 'REPORT_GENERATOR_STATUS';
export const REPORT_GENERATOR_MESSAGE = 'REPORT_GENERATOR_MESSAGE';

export const EXOTEL_MISSEDCALL_STATUS = 'EXOTEL_MISSEDCALL_STATUS';
export const EXOTEL_MISSEDCALL = 'EXOTEL_MISSEDCALL';
export const EXOTEL_NATIONAL_STATUS = 'EXOTEL_NATIONAL_STATUS';
export const EXOTEL_MESSAGE = 'EXOTEL_MESSAGE';
export const SET_CALL_STATUS = 'SET_CALL_STATUS';

export const VOLUNTEER_INFO = 'VOLUNTEER_INFO';
export const VOLUNTEER_STATES = 'VOLUNTEER_STATES';
export const VOLUNTEER_INFO_UPDATE = 'VOLUNTEER_INFO_UPDATE';
export const VOLUNTEER_INFO_STATUS = 'VOLUNTEER_INFO_STATUS';
export const VOLUNTEER_INFO_UPDATE_STATUS = 'VOLUNTEER_INFO_UPDATE_STATUS';
export const VOLUNTEER_INFO_STATE_LIST = 'VOLUNTEER_INFO_STATE_LIST';
export const VOLUNTEER_INFO_STATE = 'VOLUNTEER_INFO_STATE';
export const VOLUNTEER_INFO_STATE_FEATURES = 'VOLUNTEER_INFO_STATE_FEATURES';
export const VOLUNTEER_INFO_STATE_SIDEBAR = 'VOLUNTEER_INFO_STATE_SIDEBAR';
export const VOLUNTEER_INFO_STATE_ROUTES = 'VOLUNTEER_INFO_STATE_ROUTES';

// export const SET_caller_Call_Assign='SET_caller_Call_Assign';
// export const SET_DASHBOARD_CALL_DETAILS = 'SET_DASHBOARD_CALL_DETAILS';
// export const SET_TOTAL_CALLERS = 'SET_TOTAL_CALLERS';
// export const SET_DASHBOARD_CALL_DETAILS_Duration = 'SET_DASHBOARD_CALL_DETAILS_Duration';
// export const SET_TASK_TYPE = 'SET_TASK_TYPE';
// export const SET_TASK_STATUS = 'SET_TASK_STATUS';
// export const SET_BULK_SATUS='SET_BULK_SATUS';
// export const SET_TasksList_DATA ='SET_TasksList_DATA';
// export const SET_TasksList_RESULT='SET_TasksList_RESULT';
// export const SET_TasksList_Link = 'SET_TasksList_Link';
// export const SET_TASKASSIGN_RESPONCE = 'SET_TASKASSIGN_RESPONCE';
// export const SET_National_Bangalore = 'SET_National_Bangalore';
// export const SET_National_Bombay = 'SET_National_Bombay';
// export const SET_National_Dehradun = 'SET_National_Dehradun';
// export const SET_National_Delhi = 'SET_National_Delhi';
// export const SET_National_Lucknow = 'SET_National_Lucknow';
// export const SET_National_Rajasthan = 'SET_National_Rajasthan';
// export const SET_National_Raipur = 'SET_National_Raipur';
// export const SET_National_Orissa = 'SET_National_Orissa';
// export const SET_National_Bhopal = 'SET_National_Bhopal';
// export const SET_ProfileChange='SET_ProfileChange';

// // export const PasswordUpdateApiData ='PasswordUpdateApiData';

// export const SET_TIMEWISEDATA_National_Bangalore = 'SET_TIMEWISEDATA_National_Bangalore';
// export const SET_TIMEWISEDATA_National_Bombay = 'SET_TIMEWISEDATA_National_Bombay';
// export const SET_TIMEWISEDATA_National_Dehradun = 'SET_TIMEWISEDATA_National_Dehradun';
// export const SET_TIMEWISEDATA_National_Delhi = 'SET_TIMEWISEDATA_National_Delhi';
// export const SET_TIMEWISEDATA_National_Lucknow = 'SET_TIMEWISEDATA_National_Lucknow';
// export const SET_TIMEWISEDATA_National_Rajasthan = 'SET_TIMEWISEDATA_National_Rajasthan';
// export const SET_TIMEWISEDATA_National_Raipur = 'SET_TIMEWISEDATA_National_Raipur';
// export const SET_TIMEWISEDATA_National_Orissa = 'SET_TIMEWISEDATA_National_Orissa';
// export const SET_TIMEWISEDATA_National_Bhopal = 'SET_TIMEWISEDATA_National_Bhopal';
// //local
// export const SelectedTask='SelectedTask';
// export const ResetEveryThingStatus='ResetEveryThingStatus';
// export const SET_LoginStatus = 'SET_LoginStatus';
// // export const SET_ProfileChange='SET_ProfileChange';
// // export const PasswordUpdateApiResponse='PasswordUpdateApiResponse';
// // export const SET_LOGIN_PENDING = 'SET_LOGIN_PENDING';
// // export const SET_USER_RESPONSE = 'SET_USER_RESPONSE';
// // export const SET_USER_BODY = 'SET_USER_BODY';
// // export const SET_USER_TOKEN='SET_USER_TOKEN';
// // export const PasswordUpdateApiData ='PasswordUpdateApiData';
// // export const PasswordUpdateApiResponse='PasswordUpdateApiResponse';
// // export const SET_USER_TOKEN='SET_USER_TOKEN';
// // export const PasswordUpdateApiData ='PasswordUpdateApiData';
// // export const PasswordUpdateApiResponse='PasswordUpdateApiResponse';
// // export const SET_LOGIN_PENDING = 'SET_LOGIN_PENDING';
// // export const SET_USER_RESPONSE = 'SET_USER_RESPONSE';
// // export const SET_USER_BODY = 'SET_USER_BODY';
// // export const SET_USER_TOKEN='SET_USER_TOKEN';
// // export const PasswordUpdateApiData ='PasswordUpdateApiData';
// // export const PasswordUpdateApiResponse='PasswordUpdateApiResponse';
// // export const SET_USER_TOKEN='SET_USER_TOKEN';
// // export const PasswordUpdateApiData ='PasswordUpdateApiData';
// // export const PasswordUpdateApiResponse='PasswordUpdateApiResponse';
// // export const SET_LOGIN_PENDING = 'SET_LOGIN_PENDING';
// // export const SET_USER_RESPONSE = 'SET_USER_RESPONSE';
// // export const SET_USER_BODY = 'SET_USER_BODY';
// // export const SET_USER_TOKEN='SET_USER_TOKEN';
// // export const PasswordUpdateApiData ='PasswordUpdateApiData';
// // export const PasswordUpdateApiResponse='PasswordUpdateApiResponse';
// // export const SET_USER_TOKEN='SET_USER_TOKEN';
// // export const PasswordUpdateApiData ='PasswordUpdateApiData';
// // export const PasswordUpdateApiResponse='PasswordUpdateApiResponse';
// // export const SET_LOGIN_PENDING = 'SET_LOGIN_PENDING';
// // export const SET_USER_RESPONSE = 'SET_USER_RESPONSE';
// // export const SET_USER_BODY = 'SET_USER_BODY';
// // export const SET_USER_TOKEN='SET_USER_TOKEN';
// // export const PasswordUpdateApiData ='PasswordUpdateApiData';
// // export const PasswordUpdateApiResponse='PasswordUpdateApiResponse';
// // export const SET_USER_TOKEN='SET_USER_TOKEN';
// // export const PasswordUpdateApiData ='PasswordUpdateApiData';
// // export const PasswordUpdateApiResponse='PasswordUpdateApiResponse';
// // export const SET_LOGIN_PENDING = 'SET_LOGIN_PENDING';
// // export const SET_USER_RESPONSE = 'SET_USER_RESPONSE';
// // export const SET_USER_BODY = 'SET_USER_BODY';
// // export const SET_USER_TOKEN='SET_USER_TOKEN';
// // export const PasswordUpdateApiData ='PasswordUpdateApiData';
// // export const PasswordUpdateApiResponse='PasswordUpdateApiResponse';
// // export const SET_USER_TOKEN='SET_USER_TOKEN';
// // export const PasswordUpdateApiData ='PasswordUpdateApiData';
// // export const PasswordUpdateApiResponse='PasswordUpdateApiResponse';
// // export const SET_LOGIN_PENDING = 'SET_LOGIN_PENDING';
// // export const SET_USER_RESPONSE = 'SET_USER_RESPONSE';
// // export const SET_USER_BODY = 'SET_USER_BODY';
// // export const SET_USER_TOKEN='SET_USER_TOKEN';
// // export const PasswordUpdateApiData ='PasswordUpdateApiData';
// // export const PasswordUpdateApiResponse='PasswordUpdateApiResponse';

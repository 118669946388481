import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from '../store/actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
      messages: [],
      email: '',
      password: '',
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      valid: true,
    });
  };

  validate = () => {
    const { email, password } = this.state;
    let valid = true;
    const messages = [];
    if (!email) {
      valid = false;
      messages.push('Email is required');
    }
    if (!password) {
      valid = false;
      messages.push('Password is required');
    }
    this.setState({
      messages,
      valid,
    });
    return valid;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { login } = this.props;
    const { email, password } = this.state;
    const valid = this.validate();
    if (valid) {
      await login({ email, password });
    }
  };

  render() {
    const {
      email, password, valid, messages,
    } = this.state;
    const { status, errorMessage } = this.props;
    let show = false;
    if (!valid || status > 300) {
      show = true;
    }
    return (
      <div className="card">
        <div className="card-body">
          <h1 className="mb-5">Login</h1>
          <div
            className="card bg-danger"
            style={{
              display: show ? 'block' : 'none',
            }}
          >
            <div className="card-header">Error Messages</div>
            <div className="card-body">
              <ul>
                {errorMessage}
                {messages.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            </div>
          </div>
          <form onSubmit={this.onSubmit} className="mb-3">
            <div className="form-group">
              <label>Email address</label>
              <input
                type="email"
                name="email"
                className="form-control"
                value={email}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                className="form-control"
                value={password}
                onChange={this.handleChange}
              />
            </div>
            <button
              type="submit"
              className={`btn btn-${valid ? 'primary' : 'danger'}`}
              disabled={!valid}
            >
              Login
            </button>
          </form>
          <Link className="text-decoration-none" to="/covidhelpline/forgotpassword">
            Forgot Password?
          </Link>
        </div>
        <div className="card-footer">
          Not Registered?&nbsp;
          <Link className="text-decoration-none" to="/covidhelpline/register">
            Click here to go to Registration Page.
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  status: state.covidWorkflow.loginStatus,
  errorMessage: state.covidWorkflow.error,
});

export default connect(mapStateToProps, {
  login: actions.login,
})(Login);

import {
  USER_INFO,
  USER_STATES,
  USER_INFO_UPDATE,
  USER_INFO_STATUS,
  USER_INFO_UPDATE_STATUS,
  USER_INFO_STATE_LIST,
  USER_INFO_STATE,
  USER_INFO_STATE_FEATURES,
  USER_INFO_STATE_SIDEBAR,
  USER_INFO_STATE_ROUTES,
} from '../action/Types';
import initialState from './InitialState';

export default function (state = initialState.user, action) {
  switch (action.type) {
    case USER_INFO:
      return {
        ...state,
        info: action.payload,
      };
    case USER_STATES:
      return {
        ...state,
        states: action.payload,
      };
    case USER_INFO_STATUS:
      return {
        ...state,
        infoStatus: action.payload,
      };
    case USER_INFO_UPDATE_STATUS:
      return {
        ...state,
        updateStatus: action.payload,
      };
    case USER_INFO_UPDATE:
      return {
        ...state,
        updateResponce: action.payload,
      };
    case USER_INFO_STATE:
      return {
        ...state,
        state: action.payload,
      };
    case USER_INFO_STATE_LIST:
      return {
        ...state,
        states: action.payload,
      };
    case USER_INFO_STATE_FEATURES:
      return {
        ...state,
        features: action.payload,
      };
    case USER_INFO_STATE_SIDEBAR:
      return {
        ...state,
        sideBar: action.payload,
      };
    case USER_INFO_STATE_ROUTES:
      return {
        ...state,
        routes: action.payload,
      };
    default:
      return state;
  }
}

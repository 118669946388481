import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  root: {
    width: '100%',
    minWidth: 100,
  },
}));

export default function PopoverCell({ statusArray, item }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const statusNames = Object.keys(statusArray);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.target);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <td
      style={{ cursor: 'pointer' }}
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Typography>{item}</Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List className={classes.root} dense>
          {statusNames.map(name => (statusArray[name] === 0 ? null : (
              <ListItem>
                <ListItemText primary={name} />
                <ListItemSecondaryAction>
                  <ListItemText edge="end" primary={statusArray[name]} />
                </ListItemSecondaryAction>
              </ListItem>
            )))}
        </List>
      </Popover>
    </td>
  );
}

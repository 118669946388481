import { combineReducers } from 'redux';
import {
  auth as formioAuth,
  form,
  forms,
  submission,
  submissions,
} from 'react-formio';
import auth from './AuthReducer';
import user from './UserReducer';
import formio from './FormioReducer';
import userManagement from './UserManagementReducer';
import callerManagement from './CallerManagementReducer';
import taskManagement from './TaskManagementReducer';
import taskAssignment from './TaskAssignmentReducer';
import notification from './NotificationReducer';
import dashboard from './Dashboard';
import bulkUpload from './BulkUpload';
import bulkUploadSource from './SourceReducer';
import reportGenerator from './ReportGeneratorReducer';
import covidWorkflow from '../../covidWorkflow/store/reducer';
// FORMIO REDUCERS
import exotel from './MissedCallExotel';
// import functionalLead from "../../FunctionalLeads/Redux/Reducer/index";
// import stateAdmin from "../../StateAdmin/Redux/Reducer/index";

export default combineReducers({
  auth,
  user,
  formio,
  userManagement,
  callerManagement,
  taskManagement,
  taskAssignment,
  notification,
  dashboard,
  bulkUpload,
  bulkUploadSource,
  reportGenerator,
  covidWorkflow,
  // formioStore
  formioAuth: formioAuth(),
  form: form({ name: 'form' }),
  forms: forms({ name: 'forms', query: { type: 'form', tags: 'common' } }),
  submission: submission({ name: 'submission' }),
  submissions: submissions({ name: 'submissions' }),
  event: combineReducers({
    form: form({ name: 'event' }),
    submission: submission({ name: 'event' }),
    submissions: submissions({ name: 'event' }),
  }),
  exotel,
});

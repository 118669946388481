import React, { Component } from 'react';
import { Link, Route, withRouter } from 'react-router-dom';

const sidebarStyle = {
  width: 'inherit',
  position: 'sticky',
  WebkitPosition: 'sticky',
  top: '56px',
  left: '0',
  height: 'calc(100vh - 56px)',
  zIndex: '999',
  transition: 'all 0.3s',
  textDecoration: 'none',
};

const Sidebar = function (props) {
  const { location: { pathname } } = props;
  return (
    <nav className="pt-3 px-1 bg-secondary" style={sidebarStyle}>
      <Link
        className="btn btn-outline-dark btn-block rounded-0"
        to="/covidhelpline/stats"
      >
          Statistics
      </Link>
      <Link
        className="btn btn-outline-dark btn-block rounded-0 disabled"
        to="/covidhelpline/profile"
      >
          Profile
      </Link>
      <Link
        className="btn btn-outline-dark btn-block rounded-0"
        to="/covidhelpline/calls"
      >
          Calls
      </Link>
      <Route
        path="/covidhelpline/calls"
        render={() => (
          <div className="shadow">
            <Link
              className={`row btn btn-sm btn-block rounded-0 border-0 d-flex justify-content-start m-0 ${
                  pathname === '/covidhelpline/calls/td'
                    ? 'btn-dark disabled'
                    : 'btn-outline-dark'
              }`}
              to="/covidhelpline/calls/td"
            >
              <i className="fas fa-arrow-right pt-1"></i>&nbsp;&nbsp;To Do
            </Link>
            <Link
              className={`row btn btn-sm btn-block rounded-0 border-0 d-flex justify-content-start m-0 ${
                  pathname === '/covidhelpline/calls/ip'
                    ? 'btn-dark disabled'
                    : 'btn-outline-dark'
              }`}
              to="/covidhelpline/calls/ip"
            >
              <i className="fas fa-arrow-right pt-1"></i>&nbsp;&nbsp;In
                Progress
            </Link>
          </div>
        )}
      />
    </nav>
  );
};

export default withRouter(Sidebar);

import React, { Component } from 'react';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  FormGroup,
} from 'reactstrap';

class Username extends Component {
  constructor(props) {
    super(props);
    const { required } = this.props;
    this.state = {
      validate: {},
      required,
    };
  }

  onChange = (e) => {
    const { onChange } = this.props;
    this.validate(e);
    onChange(e);
  };

  validate = (e) => {
    // eslint-disable-next-line no-useless-escape
    const emailRex = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    const mobileRex = /^[1-9]\d{9}$/;
    const { validate, required } = this.state;
    const { onValidation } = this.props;
    const { name, value } = e.target;
    if (!value && required) {
      validate[name] = 'empty';
    } else if (emailRex.test(value) || mobileRex.test(value)) {
      validate[name] = 'success';
    } else {
      validate[name] = 'danger';
    }
    const state = validate[name] === 'success';
    onValidation(name, state);
    this.setState({ validate });
  };

  render() {
    const { submit, name, placeholder } = this.props;
    const { validate } = this.state;
    const username = validate[name];
    return (
      <FormGroup>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-user" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            name={name}
            placeholder={placeholder}
            onChange={this.onChange}
            type="text"
            autoComplete="username"
            valid={username === 'success'}
            invalid={username === 'empty' || username === 'danger' || submit}
          />
          {/* <FormFeedback invalid="true">Username cannot be empty</FormFeedback> */}
          <FormFeedback data-test="username_error" invalid="true">
            {username === 'empty' || submit
              ? 'Mobile or email cannot be empty'
              : 'Mobile or email format is incorrect.'}
          </FormFeedback>
        </InputGroup>
      </FormGroup>
    );
  }
}

export default Username;

import { isNull } from 'util';
import {
  STATE_TASKCOUNT_STATUS,
  STATE_TASKCOUNT,
  ALLSTATE_TASKCOUNT_STATUS,
  ALLSTATE_TASKCOUNT,
  CALLER_TASKCOUNT_STATUS,
  CALLER_TASKCOUNT,
} from '../action/Types';
import initialState from './InitialState';

export default function (state = initialState.dashboard, action) {
  const oldState = { ...state };
  switch (action.type) {
    case STATE_TASKCOUNT_STATUS:
      return {
        ...state,
        stateTotalCount: { ...state.stateTotalCount, status: action.payload },
      };
    case STATE_TASKCOUNT:
      return {
        ...state,
        stateTotalCount: { ...state.stateTotalCount, data: action.payload },
      };
    case ALLSTATE_TASKCOUNT_STATUS:
      return {
        ...state,
        nationalTotalCount: {
          ...state.nationalTotalCount,
          status: action.payload,
        },
      };
    case ALLSTATE_TASKCOUNT:
      return {
        ...state,
        nationalTotalCount: {
          ...state.nationalTotalCount,
          data: action.payload,
        },
      };
    case CALLER_TASKCOUNT_STATUS:
      return {
        ...state,
        callerTotalCount: { ...state.callerTotalCount, status: action.payload },
      };
    case CALLER_TASKCOUNT:
      return {
        ...state,
        callerTotalCount: { ...state.callerTotalCount, data: action.payload },
      };

    default:
      return state;
  }
}

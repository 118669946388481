import {
  EXOTEL_MISSEDCALL,
  EXOTEL_MISSEDCALL_STATUS,
  EXOTEL_NATIONAL_STATUS,
  EXOTEL_MESSAGE,
  SET_CALL_STATUS,
} from '../action/Types';
import initialState from './InitialState';

export default function (state = initialState.exotel, action) {
  switch (action.type) {
    case EXOTEL_MISSEDCALL_STATUS:
      return {
        ...state,
        exotelCallCount: { ...state.exotelCallCount, status: action.payload },
      };
    case EXOTEL_MISSEDCALL:
      return {
        ...state,
        exotelCallCount: { ...state.exotelCallCount, data: action.payload },
      };
    case EXOTEL_NATIONAL_STATUS:
      return {
        ...state,
        exotelNationalCount: {
          ...state.exotelNationalCount,
          status: action.payload,
        },
      };
    case EXOTEL_MESSAGE:
      return {
        ...state,
        exotelNationalCount: {
          ...state.exotelNationalCount,
          data: action.payload,
        },
      };
    case SET_CALL_STATUS:
      return {
        ...state,
        initiateCallStatus: action.payload,
      };
    default:
      return state;
  }
}

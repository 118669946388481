/* eslint-disable import/no-cycle */
import {
  TASKASSIGNMENT_STATE,
  TASKASSIGNMENT_ALLTASK,
  TASKASSIGNMENT_ALLTASK_STATUS,
  TASKASSIGNMENT_ADDTASK,
  TASKASSIGNMENT_ADDTASK_STATUS,
  TASKASSIGNMENT_UPDATETASK_STATUS,
  TASKASSIGNMENT_UPDATETASK,
  TASKASSIGNMENT_SINGLEFORM,
  TASKASSIGNMENT_SINGLEFORM_STATUS,
  TASKASSIGNMENT_PERSISTENTFORM,
  TASKASSIGNMENT_PERSISTENTFORM_STATUS,
  TASKASSIGNMENT_SINGLESUBMISSION,
  TASKASSIGNMENT_SINGLESUBMISSION_STATUS,
  TASKASSIGNMENT_PSINGLESUBMISSION,
  TASKASSIGNMENT_PSINGLESUBMISSION_STATUS,
  TASKASSIGNMENT_FORMDETS,
  TASKASSIGNMENT_FORMDETS_STATUS,
  TASKASSIGNMENT_BULKASSIGN,
  TASKASSIGNMENT_BULKASSIGN_STATUS,
  TASKASSIGNMENT_TASKSEARCH_STATUS,
  TASKASSIGNMENT_TASKSEARCH,
  TASKASSIGNMENT_ALLTEMPLATE_STATUS,
  TASKASSIGNMENT_ALLTEMPLATE,
  TASKASSIGNMENT_SENDTEMPLATE_STATUS,
  TASKASSIGNMENT_SENDTEMPLATE,
  TASKASSIGNMENT_MYTASK,
  TASKASSIGNMENT_MYTASK_STATUS,
  TASKASSIGNMENT_BULKUNDO,
  TASKASSIGNMENT_ALLTASKNOTDONE,
} from '../action/Types';
// eslint-disable-next-line import/no-cycle
import initialState from './InitialState';

export default function (state = initialState.taskAssignment, action) {
  switch (action.type) {
    case TASKASSIGNMENT_STATE:
      return {
        ...state,
        taskAssignmentState: action.payload,
      };
    case TASKASSIGNMENT_ALLTASK_STATUS:
      return {
        ...state,
        allTasks: { ...state.allTasks, status: action.payload },
      };
    case TASKASSIGNMENT_ALLTASK:
      return {
        ...state,
        allTasks: { ...state.allTasks, data: action.payload },
      };
    case TASKASSIGNMENT_ALLTASKNOTDONE:
      return {
        ...state,
        allTasksNotDone: {
          ...state.allTasksNotDone,
          status: action.payload.status,
          data: action.payload.data,
        },
      };
    case TASKASSIGNMENT_ADDTASK_STATUS:
      return {
        ...state,
        addTasks: { ...state.addTasks, status: action.payload },
      };
    case TASKASSIGNMENT_ADDTASK:
      return {
        ...state,
        addTasks: { ...state.addTasks, data: action.payload },
      };
    case TASKASSIGNMENT_SINGLEFORM_STATUS:
      return {
        ...state,
        singleForm: { ...state.singleForm, status: action.payload },
      };
    case TASKASSIGNMENT_SINGLEFORM:
      return {
        ...state,
        singleForm: { ...state.singleForm, data: action.payload },
      };
    case TASKASSIGNMENT_PERSISTENTFORM_STATUS:
      return {
        ...state,
        singlePForm: { ...state.singlePForm, status: action.payload },
      };
    case TASKASSIGNMENT_PERSISTENTFORM:
      return {
        ...state,
        singlePForm: { ...state.singlePForm, data: action.payload },
      };
    case TASKASSIGNMENT_SINGLESUBMISSION_STATUS:
      return {
        ...state,
        singleFormSub: { ...state.singleFormSub, status: action.payload },
      };
    case TASKASSIGNMENT_SINGLESUBMISSION:
      return {
        ...state,
        singleFormSub: { ...state.singleFormSub, data: action.payload },
      };
    case TASKASSIGNMENT_PSINGLESUBMISSION_STATUS:
      return {
        ...state,
        singlePFormSub: { ...state.singlePFormSub, status: action.payload },
      };
    case TASKASSIGNMENT_PSINGLESUBMISSION:
      return {
        ...state,
        singlePFormSub: { ...state.singlePFormSub, data: action.payload },
      };
    case TASKASSIGNMENT_FORMDETS_STATUS:
      return {
        ...state,
        formDets: { ...state.formDets, status: action.payload },
      };
    case TASKASSIGNMENT_FORMDETS:
      return {
        ...state,
        formDets: { ...state.formDets, data: action.payload },
      };
    case TASKASSIGNMENT_UPDATETASK_STATUS:
      return {
        ...state,
        updatetask: { ...state.updatetask, status: action.payload },
      };
    case TASKASSIGNMENT_UPDATETASK:
      return {
        ...state,
        updatetask: { ...state.updatetask, data: action.payload },
      };
    case TASKASSIGNMENT_BULKASSIGN_STATUS:
      return {
        ...state,
        bulkassign: { ...state.bulkassign, status: action.payload },
      };
    case TASKASSIGNMENT_BULKASSIGN:
      return {
        ...state,
        bulkassign: { ...state.bulkassign, data: action.payload },
      };
    case TASKASSIGNMENT_BULKUNDO:
      return {
        ...state,
        bulkundo: {
          ...state.bulkundo,
          status: action.payload.status,
          data: action.payload.data,
        },
      };
    case TASKASSIGNMENT_TASKSEARCH_STATUS:
      return {
        ...state,
        taskSearch: { ...state.taskSearch, status: action.payload },
      };
    case TASKASSIGNMENT_TASKSEARCH:
      return {
        ...state,
        taskSearch: { ...state.taskSearch, data: action.payload },
      };
    case TASKASSIGNMENT_ALLTEMPLATE_STATUS:
      return {
        ...state,
        template: { ...state.template, status: action.payload },
      };
    case TASKASSIGNMENT_ALLTEMPLATE:
      return {
        ...state,
        template: { ...state.template, data: action.payload },
      };
    case TASKASSIGNMENT_SENDTEMPLATE_STATUS:
      return {
        ...state,
        sendsms: { ...state.sendsms, status: action.payload },
      };
    case TASKASSIGNMENT_SENDTEMPLATE:
      return {
        ...state,
        sendsms: { ...state.sendsms, data: action.payload },
      };
    case TASKASSIGNMENT_MYTASK_STATUS:
      return {
        ...state,
        myTasks: { ...state.myTasks, status: action.payload },
      };
    case TASKASSIGNMENT_MYTASK:
      return {
        ...state,
        myTasks: { ...state.myTasks, data: action.payload },
      };
    default:
      return state;
  }
}

import Cookies from 'universal-cookie';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
const cookies = new Cookies();

class Exotel {
  static ExotelMissedCallCountApi = data => fetch(
      `${baseUrl}/tasks/missedCallReport${
        data.from ? `?from=${data.from}` : ``
      }${data.to ? `${data.from ? `&` : `?`}to=${data.to}` : ``}${
        data.state_id
          ? `${data.from || data.to ? `&` : `?`}state_id=${data.state_id}`
          : ``
      }`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json, text/plain, */*',
          authorization_token: cookies.get('token'),
        },
      },
  );
}

export default Exotel;

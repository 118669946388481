export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_TOKEN = 'SET_TOKEN';
export const LOGOUT = 'LOGOUT';
export const SET_ALLOCATED_BENEFICIARIES = 'SET_ALLOCATED_BENEFICIARIES';
export const SET_STATE = 'SET_STATE';
export const SET_CALL_ALLOCATION_STATUS = 'SET_CALL_ALLOCATION_STATUS';
export const SET_CALL_ALLOCATION = 'SET_CALL_ALLOCATION';
export const SET_LANGUAGES_STATUS = 'SET_LANGUAGES_STATUS';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const SET_REGISTRATION_STATUS = 'SET_REGISTRATION_STATUS';
export const SET_UPDATE_BENEFICIARY_STATUS = 'SET_UPDATE_BENEFICIARY_STATUS';
export const SET_FORM = 'SET_FORM';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const SET_STATES = 'SET_STATES';
export const SET_STATES_STATUS = 'SET_STATES_STATUS';
export const SET_USER_STATS = 'SET_USER_STATS';
export const SET_WINDOW_DIMENSIONS = 'SET_WINDOW_DIMENSIONS';
export const SET_SEND_RESET_LINK_STATUS = 'SET_SEND_RESET_LINK_STATUS';
export const SET_RESET_PASSWORD_STATUS = 'SET_RESET_PASSWORD_STATUS';
export const SET_INITIATE_CALL_STATUS = 'SET_INITIATE_CALL_STATUS';

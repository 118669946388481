/* eslint-disable no-var */
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
import React from 'react';
import PopoverCell from './PopoverCell';

export function CallSum(data) {
  const TempArr = [];
  const status = [];
  for (const i in data) {
    if (data[i] !== 'ok') {
      const chaild = data[i];
      // eslint-disable-next-line vars-on-top
      var tempvar = 0;
      for (const j in chaild) {
        // eslint-disable-next-line no-plusplus
        tempvar++;
        status.push(j);
        TempArr.push(0);
      }
      let counter = 0;
      for (const k in chaild) {
        if (status[counter] === k) {
          // eslint-disable-next-line operator-assignment
          TempArr[counter] = TempArr[counter] + chaild[k];

          counter++;
        }
      }
    }
  }
  return {
    // eslint-disable-next-line block-scoped-var
    SumArry: TempArr.slice(0, tempvar),
    // eslint-disable-next-line block-scoped-var
    status: status.slice(0, tempvar),
  };
}

export function createTable(calls) {
  const TdTag = [];

  for (const key in calls) {
    const childrenTd = [];
    const userArray = calls[key];
    if (key !== 'status') {
      childrenTd.push(<td>{key}</td>);
      for (const i in userArray) {
        let item = 0;
        for (const j in userArray[i]) {
          item += userArray[i][j];
        }

        if (i !== 'In Progress') {
          childrenTd.push(<td>{item}</td>);
        } else if (item === 0) {
          // when InProgress is {} or total inprogress is 0
          childrenTd.push(<td>{item}</td>);
        } else {
          childrenTd.push(
            <PopoverCell statusArray={userArray[i]} item={item} />,
          );
        }
      }
    }

    TdTag.push(<tr>{childrenTd}</tr>);
  }

  return TdTag;
}
export function heding(calls) {
  const ThTag = [];
  const children = [];
  children.push(<td>Task \ Status</td>);
  let count = 0;

  for (const key in calls) {
    const userArray = calls[key];

    if (key !== 'status') {
      for (const i in userArray) {
        count = 1;

        children.push(<td>{i}</td>);
      }
    }
    if (count === 1) {
      break;
    }
  }
  ThTag.push(<tr>{children}</tr>);
  return ThTag;
}
export function calculatesumofcalls(state) {
  let totalCalls = 0;
  let totalToDo = 0;
  let totalInProgrees = 0;
  let totalDone = 0;

  // eslint-disable-next-line array-callback-return
  state.map((call) => {
    const { data } = call;
    for (const key in data) {
      const userArray = data[key];
      if (key !== 'status') {
        for (const i in userArray) {
          for (const j in userArray[i]) {
            totalCalls += userArray[i][j];
            if (i === 'To Do') totalToDo += userArray[i][j];
            if (i === 'In Progress') totalInProgrees += userArray[i][j];
            if (i === 'Done') totalDone += userArray[i][j];
          }
        }
      }
    }
  });

  return {
    totalToDo,
    totalInProgrees,
    totalDone,
    totalCalls,
  };
}

import React from 'react';

const Dashboard = React.lazy(() => import('../../components/dashboard/Dashboard'));
const Users = React.lazy(() => import('../../components/users/Users'));
const User = React.lazy(() => import('../../components/users/User'));
const Policies = React.lazy(() => import('../../features/policies/Policies'));
const States = React.lazy(() => import('../../features/states/States'));
const AddPolicy = React.lazy(() => import('../../features/policies/AddPolicy'));
const PolicyStates = React.lazy(() => import('../../components/state_table/States'));
const PolicyState = React.lazy(() => import('../../components/state_table/State'));
const FormManager = React.lazy(() => import('../../components/form_manager'));
const National = React.lazy(() => import('../../components/national/National'));
const Profile = React.lazy(() => import('../default_view/Profile'));
// const Settings = React.lazy(() => import('../default_view/Settings'));
const PermissionManager = React.lazy(() => import('../../features/user_management/feature_permissions'));
const CallerManager = React.lazy(() => import('../../features/caller_management/CallerList'));
const UsersList = React.lazy(() => import('../../features/user_management/UsersList'));
const TaskAssignment = React.lazy(() => import('../../features/task_assignment'));
const TaskSetting = React.lazy(() => import('../../features/task_setting'));
const Default = React.lazy(() => import('../../features/state_selection/StateSelection'));
const Form = React.lazy(() => import('../../features/form_management/index'));
const BulkUpload = React.lazy(() => import('../../features/bulk_upload/index'));
const ReportGenerator = React.lazy(() => import('../../features/report_generator/ReportGenerator'));
const MyTasks = React.lazy(() => import('../../features/my_tasks/MyTasks'));
const Volunteer = React.lazy(() => import('../../features/volunteer/Volunteer'));

export default {
  Dashboard,
  National,
  Users,
  User,
  Policies,
  States,
  AddPolicy,
  PolicyStates,
  PolicyState,
  FormManager,
  Profile,
  // Settings,
  PermissionManager,
  CallerManager,
  UsersList,
  TaskAssignment,
  TaskSetting,
  Default,
  Form,
  BulkUpload,
  ReportGenerator,
  MyTasks,
  Volunteer,
};

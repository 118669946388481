import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from '../store/actions';
import FilteredDropdown from './FilteredDropdown';
import MultiSelect from './MultiSelect';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
      messages: [],
      name: '',
      email: '',
      phone: '',
      city: '',
      languages: [],
      password: '',
      confirm: '',
      state: { id: '', name: '' },
    };
  }

  componentDidMount() {
    const { getListOfLanguages, getListOfStates } = this.props;
    getListOfLanguages();
    getListOfStates();
  }

  validate = () => {
    const {
      email, phone, password, confirm, state,
    } = this.state;
    let valid = true;
    const messages = [];
    if (!email) {
      valid = false;
      messages.push('Email is required');
    }
    if (!phone) {
      valid = false;
      messages.push('Phone Number is required');
    } else if (!/^[1-9][0-9]{9}/.test(phone)) {
      valid = false;
      messages.push('Entered Phone Number is incorrect');
    }
    if (!password) {
      valid = false;
      messages.push('Password is required');
    } else if (!confirm) {
      valid = false;
      messages.push('Please confirm your password');
    } else if (password !== confirm) {
      valid = false;
      messages.push('Passwords do not match');
    } else if (password.length < 6) {
      valid = false;
      messages.push('Password needs to be more than 6 characters');
    }
    if (!state.id) {
      valid = false;
      messages.push('State is required');
    }
    this.setState({
      messages,
      valid,
    });
    return valid;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { clearErrorMessages } = this.props;
    this.setState({
      [name]: value,
      valid: true,
    });
    clearErrorMessages();
  };

  selectState = (name, object) => {
    const { clearErrorMessages } = this.props;
    this.setState({
      [name]: object,
      valid: true,
    });
    clearErrorMessages();
  };

  handleKey = (e) => {
    if (e.key === 'Enter') {
      this.resetLanguages();
    }
  }

  resetLanguages = () => {
    this.setState({
      languages: [],
    });
  };

  handleSelected = (selected) => {
    const { clearErrorMessages } = this.props;
    this.setState({
      languages: selected,
    });
    clearErrorMessages();
  };

  onSubmit = (e) => {
    e.preventDefault();
    const valid = this.validate();
    const {
      name, email, phone, city, languages, password, state: { id: state },
    } = this.state;
    const { register } = this.props;
    const data = {
      name,
      email,
      phone,
      city,
      languages: languages.map(language => language.name),
      password,
      state,
    };
    if (valid) {
      register(data);
    }
  };

  render() {
    const {
      name,
      email,
      phone,
      password,
      confirm,
      languages,
      valid,
      messages,
      city,
      state,
    } = this.state;
    const {
      listOfLanguages,
      listOfStates,
      status,
      errorMessage,
    } = this.props;
    let show = false;
    if (!valid || status) {
      show = true;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    return (
      <div className="card">
        <div className="card-body">
          <h1 className="mb-5">Registration</h1>
          <div
            className="card bg-danger"
            style={{
              display: show ? 'block' : 'none',
            }}
          >
            <div className="card-header">Error Messages</div>
            <div className="card-body">
              <ul>
                {errorMessage ? <li>{errorMessage}</li> : ''}
                {messages.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            </div>
          </div>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label>Name</label>
              <input
                name="name"
                type="text"
                className="form-control"
                value={name}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>Email address</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={this.handleChange}
                name="email"
              />
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <input
                type="tel"
                className="form-control"
                value={phone}
                onChange={this.handleChange}
                name="phone"
              />
            </div>
            <div className="form-group">
              <label>Location (City)</label>
              <input
                name="city"
                type="text"
                className="form-control"
                value={city}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>State</label>
              <FilteredDropdown
                name="state"
                selectedItem={state}
                list={listOfStates.map(({ id, stateName }) => ({ id, name: stateName }))}
                select={this.selectState}
                color="primary"
              />
            </div>
            <div className="form-group">
              <label>Languages:</label>
              <span
                className="badge-primary rounded-pill px-2 my-3 float-right"
                style={{ cursor: 'pointer' }}
                role="button"
                tabIndex={0}
                onKeyUp={this.handleKey}
                onClick={this.resetLanguages}
              >
                Unselect All
              </span>
              <small className="form-text text-muted">
                Please select the Languages you are proficient in.
              </small>
              <MultiSelect
                name="Select Languages"
                selected={languages}
                list={listOfLanguages.map(({ id, languageName }) => ({ id, name: languageName }))}
                handleSelected = {this.handleSelected}
                color="primary"
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={this.handleChange}
                name="password"
              />
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                className="form-control"
                value={confirm}
                onChange={this.handleChange}
                name="confirm"
              />
            </div>
            <button
              type="submit"
              className={`btn btn-${valid ? 'primary' : 'danger'}`}
              disabled={!valid}
            >
              Register
            </button>
          </form>
        </div>
        <div className="card-footer">
          Already Registered?&nbsp;
          <Link className="text-decoration-none" to="/covidhelpline/login">
            Click here to go to Login Page.
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  listOfLanguages: state.covidWorkflow.languages.data,
  listOfStates: state.covidWorkflow.states.data,
  languageLoadStatus: state.covidWorkflow.languages.status,
  registrationStatus: state.covidWorkflow.registrationStatus,
  status: state.covidWorkflow.registrationStatus,
  errorMessage: state.covidWorkflow.error,
});

export default connect(
  mapStateToProps,
  {
    getListOfLanguages: actions.getListOfLanguages,
    getListOfStates: actions.getListOfStates,
    register: actions.register,
    clearErrorMessages: actions.clearErrorMessages,
  },
)(Register);

import React, { useState } from 'react';
import { useSelector, connect } from 'react-redux';

import actions from '../store/actions';

const ForgotPassword = function ({ sendPasswordRecoveryEmail }) {
  const {
    forgotPassword: {
      sendResetPasswordLinkStatus: { message, error, pending },
    },
  } = useSelector(store => store.covidWorkflow);
  const [input, setInput] = useState({ email: '' });
  const { email } = input;

  const handleSubmit = (e) => {
    e.preventDefault();
    sendPasswordRecoveryEmail(email);
  };

  return (
    <div className="card">
      <div className="card-body">
        <h1 className="mb-5">Forgot Password</h1>
        {error
        && <>
          <div className="card bg-danger">
            <div className="card-body">
              {error === 'Validation Error'
              ? <>
                <p>User not found</p>
                <p>Please go to the Registration page</p>
              </>
              : error}
            </div>
          </div>
        </>}
        {message
        ? <>
          <p>A Link has been sent to your Email address.</p>
          <p>Please follow the link to reset your password.</p>
        </>
        : <>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Registered Email</label>
              <input
                type="email"
                name="email"
                className="form-control"
                value={email}
                onChange={(e) => {
                  setInput({
                    ...input,
                    email: e.target.value,
                  });
                }}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={pending}
            >
            Send Password Reset Link
            </button>
          </form>
        </>}
      </div>
    </div>
  );
};

export default connect(null, {
  sendPasswordRecoveryEmail: actions.sendPasswordRecoveryEmail,
})(ForgotPassword);

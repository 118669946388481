import React, { Component } from 'react';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  FormGroup,
  FormText,
} from 'reactstrap';

class Mobile extends Component {
  constructor(props) {
    super(props);
    const { required } = this.props;
    this.state = {
      validate: {},
      // eslint-disable-next-line react/no-unused-state
      required: [required],
      margin: required ? 'mb-3' : 'mb-1',
    };
  }

  onChange = (e) => {
    const { onChange } = this.props;
    this.validate(e);
    onChange(e);
  };

  validate = (e) => {
    const mobileRex = /^[1-9]\d{9}$/;
    const { validate } = this.state;
    const { onValidation, required } = this.props;
    const { name } = e.target;
    if (!e.target.value) {
      if (required) {
        validate[name] = 'empty';
      } else {
        validate[name] = 'success';
      }
    } else if (mobileRex.test(e.target.value)) {
      validate[name] = 'success';
    } else {
      validate[name] = 'danger';
    }
    const state = validate[name] === 'success';
    onValidation(name, state);
    this.setState({ validate });
  };

  render() {
    const { submit, name, placeholder } = this.props;
    const { validate, margin, required } = this.state;
    const mobile = validate[name];
    return (
      <FormGroup>
        <InputGroup className={margin}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-phone" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            name={name}
            placeholder={placeholder}
            onChange={this.onChange}
            type="tel"
            autoComplete="mobile"
            maxLength="10"
            minLength="10"
            valid={mobile === 'success'}
            invalid={mobile === 'danger' || mobile === 'empty' || submit}
          />
          <FormFeedback data-test="mobile_error" invalid="true">
            {mobile === 'empty' || submit
              ? 'Mobile number cannot be empty'
              : 'Incorrect number entered. Number cannot start with 0.Cannot contain letters.Must contain 10 digits'}
          </FormFeedback>
        </InputGroup>

        {required.map((e) => {
          if (!e) {
            return <FormText key="notRequired">Mobile is optional</FormText>;
          }
          return null;
        })}
      </FormGroup>
    );
  }
}

export default Mobile;

import Cookies from 'universal-cookie';

import MissedCallExotel from '../../service/MissedCallExotel';
import {
  EXOTEL_MESSAGE,
  EXOTEL_MISSEDCALL,
  EXOTEL_MISSEDCALL_STATUS,
  EXOTEL_NATIONAL_STATUS,
  SET_CALL_STATUS,
} from './Types';

const cookies = new Cookies();
// const stateID = cookies.get('state').StateID;

const baseUrl = `${process.env.REACT_APP_CMS_BACKEND_URL}`;

const getOptions = (
  method = 'GET',
  data = {},
  token = cookies.get('token'),
) => {
  const options = {
    method,
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization_token: token,
    }),
  };
  if (!(method === 'GET')) {
    options.body = JSON.stringify(data);
  }
  return options;
};

export const getMissedCallCount = (param = {}) => async (dispatch) => {
  const state = cookies.get('state');
  const data = {
    state_id: state ? state.id : '',
    from: param.from,
    to: param.to,
  };
  dispatch({ type: EXOTEL_MISSEDCALL_STATUS, payload: 'pending' });
  dispatch({ type: EXOTEL_MISSEDCALL, payload: [] });
  await MissedCallExotel.ExotelMissedCallCountApi(data)
    .then((response) => {
      dispatch({
        type: EXOTEL_MISSEDCALL_STATUS,
        payload: response.status,
      });

      return response.json();
    })
    .then((payload) => {
      dispatch({
        type: EXOTEL_MISSEDCALL,
        payload,
      });
    });
};

export const getNationalMissedCallCount = (param = {}) => async (dispatch) => {
  const data = {
    from: param.from,
    to: param.to,
  };

  dispatch({ type: EXOTEL_NATIONAL_STATUS, payload: 'pending' });
  dispatch({ type: EXOTEL_MESSAGE, payload: [] });
  await MissedCallExotel.ExotelMissedCallCountApi(data)
    .then((response) => {
      dispatch({
        type: EXOTEL_NATIONAL_STATUS,
        payload: response.status,
      });
      return response.json();
    })
    .then((payload) => {
      dispatch({
        type: EXOTEL_MESSAGE,
        payload,
      });
    });
};

export const initiateCall = task => (dispatch) => {
  const url = new URL(`${baseUrl}/tasks/${task}/connect`);
  fetch(url, getOptions('POST')).then((response) => {
    if (response.status < 400) {
      dispatch({
        type: SET_CALL_STATUS,
        payload: 'success',
      });
    } else {
      dispatch({
        type: SET_CALL_STATUS,
        payload: 'fail',
      });
    }
  });
};

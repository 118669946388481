import React, { useState } from 'react';
import { useSelector, connect } from 'react-redux';
import actions from '../store/actions';

const ResetPassword = function ({ history, resetPassword }) {
  const {
    forgotPassword: {
      resetPasswordStatus: { message, error, pending },
    },
  } = useSelector(store => store.covidWorkflow);
  const { search } = history.location;
  const token = (new URLSearchParams(search)).get('token') || '';
  const [input, setInput] = useState({ password: '', confirm: '', valid: false });
  const { password, confirm, valid } = input;

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword(token, password);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const valuesToSet = {
      ...input,
      [name]: value,
    };
    if (valuesToSet.password && valuesToSet.confirm) {
      valuesToSet.valid = valuesToSet.password.length >= 6
      && valuesToSet.password === valuesToSet.confirm;
    }
    setInput(valuesToSet);
  };

  if (message) {
    setTimeout(() => {
      history.push('/covidhelpline/login');
    }, 1500);
  }

  return (
    <div className="card">
      <div className="card-body">
        <h1 className="mb-5">Reset Password</h1>
        {error
        && <>
          <div className="card bg-danger">
            <div className="card-body">
              {error}
            </div>
          </div>
        </>}
        {message || <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>New Password</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={handleChange}
              name="password"
            />
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input
              type="password"
              className="form-control"
              value={confirm}
              onChange={handleChange}
              name="confirm"
            />
          </div>
          <button
            type="submit"
            className={`btn btn-${valid ? 'primary' : 'danger'}`}
            disabled={!valid || pending}
          >
          Reset Password
          </button>
        </form>}
      </div>
    </div>
  );
};

export default connect(null, {
  resetPassword: actions.resetPassword,
})(ResetPassword);

import { BULKUPLOAD_MESSAGE, BULKUPLOAD_STATUS } from '../action/Types';
import initialState from './InitialState';

export default function (state = initialState.bulkUpload, action) {
  switch (action.type) {
    case BULKUPLOAD_STATUS:
      return {
        ...state,
        bulkUpload: { ...state.bulkUpload, status: action.payload },
      };
    case BULKUPLOAD_MESSAGE:
      return {
        ...state,
        bulkUpload: { ...state.bulkUpload, data: action.payload },
      };

    default:
      return state;
  }
}

import React, { Component } from 'react';

class FilteredDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        [props.name]: '',
      },
    };
  }

  filter = (e) => {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    const {
      name, selectedItem, list, select, color, className,
    } = this.props;
    const { filter } = this.state;
    return (
      <div className="dropdown">
        <button
          className={`btn ${color ? `btn-${color} ` : ''}dropdown-toggle${
            className ? ` ${className}` : ''
          }`}
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {selectedItem.name || (
            <>
              {name.replace(/^[a-z]|[A-Z]/g, (v, i) => (i === 0 ? v.toUpperCase() : ` ${v.toLowerCase()}`))}
            </>
          )}
        </button>
        <div
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
          style={{
            overflowY: 'auto',
            maxHeight: '250px',
          }}
        >
          <input
            name={name}
            type="text"
            placeholder="Search.."
            className="dropdown-item"
            onKeyUp={this.filter}
            style={{
              WebkitPosition: 'sticky',
              position: 'sticky',
              top: '0',
              zIndex: '10',
              backgroundColor: 'white',
            }}
          />
          {list
            .filter(
              listItem => listItem.name.toUpperCase().search(filter[name].toUpperCase())
                > -1,
            )
            .map(listItem => (
              <button
                type="button"
                className="dropdown-item"
                key={listItem.id}
                onClick={() => {
                  select(name, listItem);
                }}
              >
                {listItem.name}
              </button>
            ))}
        </div>
      </div>
    );
  }
}

export default FilteredDropdown;

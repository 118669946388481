import {
  REPORT_GENERATOR_STATUS,
  REPORT_GENERATOR_MESSAGE,
} from '../action/Types';

import initialState from './InitialState';

export default (state = initialState.reportGenerator, action) => {
  switch (action.type) {
    case REPORT_GENERATOR_MESSAGE:
      return {
        ...state,
        reportResponse: { ...state.reportResponse, data: { ...action.payload } },
      };
    case REPORT_GENERATOR_STATUS:
      return {
        ...state,
        reportResponse: { ...state.reportResponse, status: action.payload },
      };
    default:
      return state;
  }
};

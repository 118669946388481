import {
  AUTH_LOGIN_STATUS,
  AUTH_LOGIN_TOKEN,
  AUTH_LOGIN_ERROR,
  AUTH_SIGNUP_STATUS,
  AUTH_SIGNUP_TOKEN,
  AUTH_SIGNUP_ERROR,
  AUTH_SEND_OTP_STATUS,
  AUTH_SEND_OTP_ERROR,
  AUTH_RESEND_OTP_STATUS,
  AUTH_RESEND_OTP_ERROR,
  AUTH_VERIFY_OTP_STATUS,
  AUTH_VERIFY_OTP_ERROR,
  AUTH_USER_MOBILE,
  AUTH_RESETPASSWORD_KEY,
  AUTH_RESETPASSWORD_STATUS,
  AUTH_RESETPASSWORD_ERROR,
  AUTH_USER_ID,
} from '../action/Types';
import initialState from './InitialState';

export default function (state = initialState.auth, action) {
  switch (action.type) {
    case AUTH_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.payload,
      };
    case AUTH_LOGIN_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case AUTH_LOGIN_ERROR:
      return {
        ...state,
        loginErrMessage: action.payload,
      };
    case AUTH_SIGNUP_STATUS:
      return {
        ...state,
        signupStatus: action.payload,
      };
    case AUTH_SIGNUP_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case AUTH_SIGNUP_ERROR:
      return {
        ...state,
        signupErrMessage: action.payload,
      };
    case AUTH_SEND_OTP_STATUS:
      return {
        ...state,
        sendOtpStatus: action.payload,
      };
    case AUTH_SEND_OTP_ERROR:
      return {
        ...state,
        sendOtpErrMessage: action.payload,
      };
    case AUTH_RESEND_OTP_STATUS:
      return {
        ...state,
        resendOtpStatus: action.payload,
      };
    case AUTH_RESEND_OTP_ERROR:
      return {
        ...state,
        resendOtpErrMessage: action.payload,
      };
    case AUTH_VERIFY_OTP_ERROR:
      return {
        ...state,
        verifyOtpErrMessage: action.payload,
      };
    case AUTH_VERIFY_OTP_STATUS:
      return {
        ...state,
        verifyOtpStatus: action.payload,
      };

    case AUTH_RESETPASSWORD_STATUS:
      return {
        ...state,
        resetPasswordStatus: action.payload,
      };
    case AUTH_RESETPASSWORD_ERROR:
      return {
        ...state,
        resetPasswordError: action.payload,
      };
    case AUTH_RESETPASSWORD_KEY:
      return {
        ...state,
        userKey: action.payload,
      };

    case AUTH_USER_MOBILE:
      return {
        ...state,
        mobile: action.payload,
      };
    case AUTH_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };

    default:
      return state;
  }
}

import React, { Component } from 'react';
import { Form, getSubmission as getSubmissionAction } from 'react-formio';
import 'formiojs/dist/formio.full.css';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { bindActionCreators } from 'redux';
import actions from '../store/actions';

const cookies = new Cookies();

const formioUrl = `${process.env.REACT_APP_FORMIO_URL}`;
class FormRenderer extends Component {
  state = {
    disableCall: false,
  }

  componentDidMount() {
    const {
      history: {
        location: {
          state: { submissionId },
        },
      },
      getSubmission,
    } = this.props;
    getSubmission(submissionId);
  }

  render() {
    const { disableCall } = this.state;
    const {
      updateBeneficiary,
      getAllocatedCalls,
      token,
      history,
      state,
      form,
      submission: oldSubmission,
      initiateCall,
      initiateCallStatus,
      history: {
        location: {
          state: { beneficiary, submission: newSubmission },
        },
      },
    } = this.props;
    const submission = newSubmission || oldSubmission;
    delete submission._id;
    let buttonColor = 'primary';
    if (initiateCallStatus) {
      buttonColor = initiateCallStatus === 'success' ? 'success' : 'danger';
    }
    return (
      <div>
        {/* <button
          type="button"
          className={`btn btn-${buttonColor} btn-block mb-2`}
          disabled = {disableCall}
          onClick={() => {
            this.setState({
              disableCall: true,
            });
            initiateCall(beneficiary.id);
          }}>
          Call{disableCall ? 'ed' : ''} Beneficiary
        </button> */}
        <Form
          src={`${formioUrl}/corona/calling`}
          url={`${formioUrl}/corona/calling`}
          submission={submission}
          onSubmitDone={({ _id: id, data }) => {
            const { newCall: { endCallStatusCategory } } = data;
            const callbackDate = data.newCall.newCallFollowUpDate
              ? (new Date(data.newCall.newCallFollowUpDate)).getTime()
              : null;
            const endCallStatus = data.newCall[endCallStatusCategory];
            const status = form.components
              .filter(component => component.key === 'endCallStatus')[0].components
              .filter(component => component.key === 'newCall')[0].components
              .filter(component => component.key === endCallStatusCategory)[0].values
              .filter(value => value.value === endCallStatus)[0];
            delete status.shortcut;
            updateBeneficiary(beneficiary.id, id, status, callbackDate);
            getAllocatedCalls(state, token || cookies.get('covidHelplineToken'));
            history.goBack();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  form: state.covidWorkflow.form,
  submission: state.submission.submission,
  token: state.covidWorkflow.token,
  state: state.covidWorkflow.state.id,
  initiateCallStatus: state.covidWorkflow.initiateCallStatus,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateBeneficiary: bindActionCreators(actions.updateBeneficiary, dispatch),
  getAllocatedCalls: bindActionCreators(actions.getAllocatedCalls, dispatch),
  getSubmission: (id) => {
    dispatch(getSubmissionAction('submission', id, ownProps.location.state.form));
  },
  initiateCall: bindActionCreators(actions.initiateCall, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormRenderer);

import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formio } from 'react-formio';
import Cookies from 'universal-cookie';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { SnackbarProvider } from 'notistack';

import './polyfill';
import App from './App';
import rootReducer from './store/reducer';
import { ErrorBoundary } from './components';

const cookies = new Cookies();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

Formio.setBaseUrl(`${process.env.REACT_APP_FORMIO_URL}`);
Formio.setProjectUrl(`${process.env.REACT_APP_FORMIO_URL}`);

const AuthenticationPlugin = {
  priority: 0,
  preRequest(requestArgs) {
    // eslint-disable-next-line no-param-reassign
    requestArgs.opts.headers['x-jwt-token'] = cookies.get('formioToken');
  },
};

Formio.registerPlugin(AuthenticationPlugin, 'auth');

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root'),
);

import React, { Component } from 'react';
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  // CardSubtitle,
  Row,
  Col,
} from 'reactstrap';
import '../../assets/css/ErrorBoundry.css';

const style = {
  marginTop: '40px',
  display: 'inline-block',
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { hasError: true };
  // }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    // console.log(error);
    // console.log(errorInfo);
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    // console.log('props');
    // console.log(this.props);
    // console.log('state');
    // console.log(this.state);

    if (hasError) {
      return (
        <Row>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <Card style={style}>
              <CardBody>
                <CardTitle className="titel">
                  Sorry Something went wrong!!!
                </CardTitle>
                {/* <CardSubtitle>Some Error Spotted here!</CardSubtitle> */}
              </CardBody>
              <img
                width="100%"
                src="https://cdn.dribbble.com/users/1078347/screenshots/2799566/oops.png"
                alt=""
              />
              <CardBody>
                <CardText>
                  Please!! Contact Administrator itsupport@indusaction.org
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    }
    return children;
  }
}

export default ErrorBoundary;

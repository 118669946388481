import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../store/actions';

class CallsToDo extends Component {
  shouldComponentUpdate(nextProps) {
    const { toDo } = this.props;
    if (JSON.stringify(toDo) !== JSON.stringify(nextProps.toDo)) {
      return true;
    }
    return false;
  }

  handleKey = (e) => {
    const {
      status, toDo, state, autoAllocateCalls,
    } = this.props;
    const number = 5 - toDo.length;
    if (e.key === 'Enter') {
      if (status && number > 0) {
        autoAllocateCalls(number, state.id);
      }
    }
  }

  render() {
    const {
      state,
      status,
      toDo,
      autoAllocateCalls,
      history,
      caller,
    } = this.props;
    const number = 5 - toDo.length;
    return (
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <h5>To Do</h5>
          <i
            className="fas fa-sync-alt bg-success p-1 text-white rounded disabled"
            style={{
              fontSize: '1.8em',
              cursor: status && number > 0 ? 'pointer' : 'not-allowed',
            }}
            onClick={() => {
              if (status && number > 0) {
                autoAllocateCalls(number, state.id);
              }
            }}
            role="button"
            tabIndex="0"
            onKeyUp={this.handleKey}
          ></i>
        </div>
        <div className="card-body p-0">
          {toDo.length === 0 ? (
            <>No Calls</>
          ) : (
            <table className="table table-hover table-borderless table-striped table-sm m-0">
              <thead>
                <tr className="table-dark">
                  <th scope="col">Name</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Location</th>
                </tr>
              </thead>
              <tbody>
                {toDo.map(
                  ({
                    id,
                    beneficiaryName,
                    beneficiaryPhone,
                    beneficiaryLocation,
                  }) => (
                    <tr
                      key={id}
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push({
                        pathname: `/covidhelpline/calls/form`,
                        state: {
                          beneficiary: {
                            id,
                          },
                          submission: {
                            data: {
                              volunteerType: 'indusActionStaffVolunteer',
                              volunteerIa: caller,
                              numberCalled: beneficiaryPhone,
                              areaOrPincode: beneficiaryLocation
                                  ? 'areaBlock'
                                  : '',
                              nameOfArea: beneficiaryLocation,
                            },
                          },
                        },
                      })
                      }
                    >
                      <td>{beneficiaryName}</td>
                      <td>{beneficiaryPhone}</td>
                      <td>{beneficiaryLocation}</td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  toDo: state.covidWorkflow.allocatedBeneficiaries.toDo,
  status: state.covidWorkflow.allocatedBeneficiaries.status,
  state: state.covidWorkflow.state,
  caller: state.covidWorkflow.user.callerData,
});

export default connect(mapStateToProps, {
  autoAllocateCalls: actions.autoAllocateCalls,
})(CallsToDo);

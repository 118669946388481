import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
// import './assets/scss/App.scss';
import { Spinner } from './components';

const loading = () => (
  <Spinner className="animated fadeIn pt-3 text-center" />
  // <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./layout/default_view'),
  loading,
});

// Pages
const Login = Loadable({
  loader: () => import('./auth/login/Login.jsx'),
  loading,
});

const Register = Loadable({
  loader: () => import('./auth/register/Register'),
  loading,
});

const Page404 = Loadable({
  loader: () => import('./auth/page404/Page404'),
  loading,
});

const Page500 = Loadable({
  loader: () => import('./auth/page500/Page500'),
  loading,
});
const resetPassword = Loadable({
  loader: () => import('./auth/reset_password/ResetPassword'),
  loading,
});
const forgotpassword = Loadable({
  loader: () => import('./auth/forgot_password/ForgotPassword'),
  loading,
});
const VerifyOTP = Loadable({
  loader: () => import('./auth/forgot_password/VerifyOTP'),
  loading,
});
const FormRenderer = Loadable({
  loader: () => import('./FormRenderer'),
  loading,
});
export {
  DefaultLayout,
  Login,
  Register,
  Page404,
  Page500,
  VerifyOTP,
  forgotpassword,
  resetPassword,
  FormRenderer,
};

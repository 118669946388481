import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Cookies from 'universal-cookie';
// import Loadable from 'react-loadable';
import './assets/scss/App.scss';
// import { Spinner } from "./components";
import {
  DefaultLayout,
  Login,
  Register,
  Page404,
  Page500,
  VerifyOTP,
  forgotpassword,
  resetPassword,
  FormRenderer,
} from './ComponentLoader';
import CovidWorkflow from './covidWorkflow/CovidWorkflow';

const cookies = new Cookies();

class DebugRouter extends Router {
  constructor(props) {
    super(props);
    this.history.listen((location, action) => {
      switch (location.pathname) {
        case '/':
        case '/admin':
        case '/admin/':
        case '/admin/default':
        case '/register':
        case '/resetPassword':
        case '/ForgotPassword':
        case '/verifyOTP':
        case '/404':
        case '/500':
        case 'covidform':
          break;
        default: {
          cookies.set('prevPath', location.pathname, {
            path: '/',
            maxAge: 86400,
          });
          break;
        }
      }
    });
  }
}

const App = () => (
  <DebugRouter>
    {/* <Router> */}
    <Switch>
      <Route
        exact
        path="/renderForm/:formname"
        name="Covid Helpline Form"
        component={FormRenderer}
      />
      <Route exact path="/" name="Login Page" component={Login} />
      <Route exact path="/register" name="Register Page" component={Register} />
      <Route
        exact
        path="/resetPassword"
        name="Reset Password"
        component={resetPassword}
      />
      <Route
        exact
        path="/ForgotPassword"
        name="Forgot Password"
        component={forgotpassword}
      />
      <Route exact path="/verifyOTP" name="Verify OTP" component={VerifyOTP} />
      <Route exact path="/404" name="Page 404" component={Page404} />
      <Route exact path="/500" name="Page 500" component={Page500} />
      <Route path="/admin" name="admin">
        <Route path="/admin/" name="home" component={DefaultLayout} />
      </Route>
      <Route
        path="/covidhelpline"
        name="Covid Helpline"
        component={CovidWorkflow}
      ></Route>
    </Switch>
    {/* </Router> */}
  </DebugRouter>
);

export default App;

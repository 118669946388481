import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Assignment';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ExpansionPanels(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // console.log(props);
  const {
    data, remove, setting, type,
  } = props;

  switch (type) {
    case 1:
      return (
        <div className={classes.root}>
          <ExpansionPanel
            expanded={expanded === data.id}
            onChange={handleChange(data.id)}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={data.id}
              id={data.id}
            >
              <Typography className={classes.secondaryHeading}>
                <span
                  style={{
                    color: ' #289cd5',
                    paddingRight: ' 11px',
                    backgroundColor: ' rgb(255, 255, 255)',
                  }}
                >
                  <FolderIcon />
                </span>
                {data.sequence}. {data.name}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  Sequence: {data.sequence}
                </Grid>
                <Grid item xs={12} sm={2}>
                  SLA: {data.sla}
                </Grid>
                <Grid item xs={6} sm={7}>
                  Cycle: {data.cycle_name}
                </Grid>
                <Grid item xs={6} sm={8}>
                  Form: {data.form.formPath}
                </Grid>
                <Grid item xs={12} sm={9}>
                  {data.description}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Tooltip title="Setting">
                    <IconButton aria-label="setting" onClick={setting}>
                      <Icon style={{ color: ' #17a2b8' }}>settings</Icon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton edge="end" aria-label="delete" onClick={remove}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    case 2:
      return (
        <div className={classes.root}>
          <ExpansionPanel
            expanded={expanded === data.id}
            onChange={handleChange(data.id)}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={data.id}
              id={data.id}
            >
              <Typography className={classes.secondaryHeading}>
                <span
                  style={{
                    color: ' #289cd5',
                    paddingRight: ' 11px',
                    backgroundColor: ' rgb(255, 255, 255)',
                  }}
                >
                  <FolderIcon />
                </span>
                {data.name}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={9}>
                  category: {data.category}
                </Grid>
                <Grid item xs={12} sm={9}>
                  {data.description}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Tooltip title="setting">
                    <IconButton aria-label="setting" onClick={setting}>
                      <Icon style={{ color: ' #17a2b8' }}>settings</Icon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="delete">
                    <IconButton edge="end" aria-label="delete" onClick={remove}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                  {data.description}
                </Grid> */}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    case 3:
      // console.log('message');

      // console.log(data);

      return (
        <div className={classes.root}>
          <ExpansionPanel
            expanded={expanded === data.id}
            onChange={handleChange(data.id)}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={data.id}
              id={data.id}
            >
              <Typography className={classes.secondaryHeading}>
                <span
                  style={{
                    color: ' #289cd5',
                    paddingRight: ' 11px',
                    backgroundColor: ' rgb(255, 255, 255)',
                  }}
                >
                  <FolderIcon />
                </span>
                {data.name}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={3}>
                {/* content: "This is the template of the message to send"
id: "d1eb1375-1877-4f88-b1d5-c2ea62eb98a7"
name: "template"
state_id: "3c44d15d-b496-473b-8d88-6df4cbf4d41f"
state_name: "Uttarakhand" */}

                <Grid item xs={12} sm={9}>
                  {data.content}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Tooltip title="setting">
                    <IconButton aria-label="setting" onClick={setting}>
                      <Icon style={{ color: ' #17a2b8' }}>settings</Icon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="delete">
                    <IconButton edge="end" aria-label="delete" onClick={remove}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                  {data.description}
                </Grid> */}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    default:
      return <div>hello</div>;
  }
}

import Cookies from 'universal-cookie';
import {
  STATE_TASKCOUNT_STATUS,
  STATE_TASKCOUNT,
  ALLSTATE_TASKCOUNT_STATUS,
  ALLSTATE_TASKCOUNT,
  CALLER_TASKCOUNT_STATUS,
  CALLER_TASKCOUNT,
} from './Types';
import Dashboard from '../../service/Dashboard';

const cookies = new Cookies();
// const stateID = cookies.get('state').id;

export const stateTaskCount = (param = {}) => async (dispatch) => {
  const data = {
    state_id: cookies.get('state').id,
    from: param.from,
    to: param.to,
  };
  // console.log(data);
  dispatch({ type: STATE_TASKCOUNT_STATUS, payload: 'pending' });
  dispatch({ type: STATE_TASKCOUNT, payload: [] });
  await Dashboard.TaskCount(data)
    .then((response) => {
      dispatch({
        type: STATE_TASKCOUNT_STATUS,
        payload: response.status,
      });
      return response.json();
    })
    .then((payload) => {
      dispatch({
        type: STATE_TASKCOUNT,
        payload,
      });
    });
};

export const nationalTaskCount = param => async (dispatch) => {
  dispatch({ type: ALLSTATE_TASKCOUNT_STATUS, payload: 'pending' });
  dispatch({ type: ALLSTATE_TASKCOUNT, payload: [] });
  await Dashboard.TaskCount({})
    .then((response) => {
      dispatch({
        type: ALLSTATE_TASKCOUNT_STATUS,
        payload: response.status,
      });
      return response.json();
    })
    .then((payload) => {
      dispatch({
        type: ALLSTATE_TASKCOUNT,
        payload,
      });
    });
};

export const callerTaskCount = param => async (dispatch) => {
  const data = {
    state_id: cookies.get('state').id,
    assignee: param.assignee,
    from: param.from,
    to: param.to,
  };
  // console.log(data);

  dispatch({ type: CALLER_TASKCOUNT_STATUS, payload: 'pending' });
  dispatch({ type: CALLER_TASKCOUNT, payload: [] });
  await Dashboard.TaskCount(data)
    .then((response) => {
      dispatch({
        type: CALLER_TASKCOUNT_STATUS,
        payload: response.status,
      });
      return response.json();
    })
    .then((payload) => {
      dispatch({
        type: CALLER_TASKCOUNT,
        payload,
      });
    });
};

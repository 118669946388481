import components from './components';

const {
  Dashboard,
  Users,
  User,
  Policies,
  States,
  AddPolicy,
  PolicyStates,
  PolicyState,
  FormManager,
  Profile,
  // Settings,
  PermissionManager,
  CallerManager,
  UsersList,
  TaskAssignment,
  TaskSetting,
  Default,
  Form,
  BulkUpload,
  ReportGenerator,
  National,
  MyTasks,
  Volunteer,
} = components;

const otherRoutes = [
  {
    path: '/admin/default',
    name: 'Select state',
    component: Default,
  },
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/admin/national',
    name: 'National',
    component: National,
  },
  {
    path: '/admin/Policies',
    exact: true,
    name: 'Policies',
    component: Policies,
  },
  {
    path: '/admin/States',
    name: 'States',
    component: States,
  },
  {
    path: '/admin/PolicyStates',
    exact: true,
    name: 'Policy States',
    component: PolicyStates,
  },
  {
    path: '/admin/PolicyState/:id',
    exact: true,
    name: 'Policy State Details',
    component: PolicyState,
  },
  {
    path: '/admin/users',
    exact: true,
    name: 'Users',
    component: Users,
  },
  {
    path: '/admin/users/:id',
    exact: true,
    name: 'User Details',
    component: User,
  },
  {
    path: '/admin/AddPolicy',
    exact: true,
    name: 'Add Policy',
    component: AddPolicy,
  },
  {
    path: '/admin/profile',
    // exact: true,
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/admin/volunteer',
    // exact: true,
    name: 'Volunteer',
    component: Volunteer,
  },
  // {
  //   path: '/admin/settings',
  //   name: 'Settings',
  //   component: Settings,
  // },
  {
    path: '/admin/bulk_upload',
    name: 'Bulk upload',
    component: BulkUpload,
  },
  {
    path: '/admin/my_tasks',
    name: 'My Tasks',
    component: MyTasks,
  },
];

const featureRoutes = {
  'User Management': [
    {
      path: '/admin/userlist',
      name: 'User Management',
      component: UsersList,
    },
  ],
  'Caller Management': [
    {
      path: '/admin/callers',
      name: 'Caller Management',
      component: CallerManager,
    },
  ],
  'Form Management': [
    {
      path: '/admin/formManager',
      name: 'Form Management',
      component: Form,
    },
    {
      path: '/admin/formManager/form-builder',
      name: 'FormBuilder',
      component: FormManager,
    },
    {
      path: '/admin/formManager/form-browser',
      name: 'FormBrowser',
      component: FormManager,
    },
  ],
  'Task Management': [
    {
      path: '/admin/taskSetting',
      name: 'Task Setting',
      exact: true,
      component: TaskSetting,
    },
    {
      path: '/admin/taskAssignment',
      name: 'Task Assignment',
      component: TaskAssignment,
    },
  ],
  'Feature Management': [
    {
      path: '/admin/userManager/permissionManager',
      name: 'Feature Management',
      component: PermissionManager,
    },
  ],
  'Calling Bank Management': [],
  'Chatbot Management': [],
  'Missed Call Assignment': [
    {
      path: '/admin/taskAssignment',
      name: 'Task Assignment',
      component: TaskAssignment,
    },
  ],
  'Report Generator': [
    {
      path: '/admin/reportgenerator',
      name: 'Report Generator',
      component: ReportGenerator,
    },
  ],
};

export default {
  otherRoutes,
  featureRoutes,
};

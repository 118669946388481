import React from 'react';
import { css } from '@emotion/core';
import { DotLoader } from 'react-spinners';

const override = css`
  position: fixed;
  top: 50%;
  left: 50%;
`;

const Loader = prop => (
  <DotLoader
    css={override}
    sizeUnit="px"
    size={80}
    color="#558dd8"
    loading={prop.loading}
  />
);
class Spinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //   loading: true,
    };
  }

  render() {
    const { classNames, active } = this.props;
    return (
      <div className={classNames}>
        <Loader loading={active} />
      </div>
    );
  }
}

export { Spinner, Loader };

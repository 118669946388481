import React, { Component } from 'react';
import FilteredDropdown from './FilteredDropdown';

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected || [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selected.length !== prevState.selected.length) {
      return {
        selected: nextProps.selected,
      };
    }
    return null;
  }

  select = (key, value) => {
    const { selected } = this.state;
    const { handleSelected } = this.props;
    selected.push(value);
    this.setState(state => ({
      ...state,
      selected,
    }));
    handleSelected(selected);
  }

  deselect = (item) => {
    let { selected } = this.state;
    const { handleSelected } = this.props;
    selected = selected.filter(x => x.id !== item.id);
    this.setState(state => ({
      ...state,
      selected,
    }));
    handleSelected(selected);
  }

  handleKey = (key, item) => {
    if (key === 'Enter') {
      this.deselect(item);
    }
  }

  render() {
    const { list, name, color } = this.props;
    const { selected } = this.state;
    return (
      <div>
        <div className="mb-2">
          {selected.map(item => (
            <span
              key={item.id}
              className="badge badge-secondary px-2 mx-1 py-1"
              role="button"
              tabIndex={0}
              style={{
                cursor: 'pointer',
              }}
              onKeyUp={e => this.handleKey(e.key, item)}
              onClick={() => this.deselect(item)}>{item.name}
            </span>
          ))}
        </div>
        <FilteredDropdown
          name={name}
          list={list.filter(x => !selected.some(e => e.name === x.name))}
          selectedItem={{}}
          select={this.select}
          color={color || 'white'}
          className="border border-black"
        />
      </div>
    );
  }
}

export default MultiSelect;

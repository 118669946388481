import React from 'react';
import { connect } from 'react-redux';

const CallsInProgress = function (props) {
  const { inProgress, history, form } = props;
  return (
    <div className="card">
      <h5 className="card-header">In Progress</h5>
      <div className="card-body p-0">
        {inProgress.length === 0 ? (
            <div className="p-3 text-center">No Calls</div>
          ) : (
            <table className="table table-hover table-borderless table-striped table-sm m-0">
              <thead>
                <tr className="table-dark">
                  <th scope="col">Name</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Location</th>
                  <th scope="col">Status</th>
                  <th scope="col">Call Back Date</th>
                </tr>
              </thead>
              <tbody>
                {inProgress.map(
                  ({
                    id,
                    beneficiaryPhone,
                    beneficiaryName,
                    beneficiaryLocation,
                    beneficiaryData,
                    status,
                    callbackDate,
                  }) => (
                    <tr
                      key={id}
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push({
                        pathname: `/covidhelpline/calls/form`,
                        state: {
                          beneficiary: {
                            id,
                          },
                          submissionId: beneficiaryData,
                          form: form._id,
                        },
                      })
                      }
                    >
                      <td>{beneficiaryName}</td>
                      <td>{beneficiaryPhone}</td>
                      <td>{beneficiaryLocation}</td>
                      <td>{status.label}</td>
                      <td>{callbackDate
                        ? (new Date(callbackDate)).toLocaleDateString()
                        : ''
                      }</td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  inProgress: state.covidWorkflow.allocatedBeneficiaries.inProgress,
  form: state.covidWorkflow.form,
});

export default connect(mapStateToProps)(CallsInProgress);

import React from 'react';
import { Link, Route, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../../assets/img/brand/miniLogo.svg';

const NavBar = function ({
  logout, history, token,
}) {
  const { location: { pathname } } = history;
  const { dimensions: { width } } = useSelector(store => store.covidWorkflow);
  return (
    <nav
      id="navbar"
      className="navbar navbar-expand-lg navbar-dark bg-github sticky-top shadow py-0 px-2"
    >
      <Link className="navbar-brand" to="/covidhelpline">
        <img
          src={logo}
          alt="Indus Action"
          className="mr-2"
          style={{
            height: '42px',
          }}
        />
        Indus Action Covid Helpline
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className={`collapse navbar-collapse ${width > 480 ? 'd-flex justify-content-end' : ''}`} id="navbarNav">
        <ul className="navbar-nav d-flex justify-content-around color-white">
          {token ? (
            <>
              {width <= 480 ? <>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/covidhelpline/stats"
                  >
                Statistics
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link disabled"
                    to="/covidhelpline/profile"
                  >
                Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/covidhelpline/calls"
                  >
                Calls
                  </Link>
                </li>
                <Route
                  path="/covidhelpline/calls"
                  render={() => (
                    <div className="shadow">
                      <Link
                        className={`row btn btn-sm btn-block rounded-0 border-0 d-flex justify-content-start m-0 ${
                      pathname === '/covidhelpline/calls/td'
                        ? 'btn-light disabled'
                        : 'btn-outline-light'
                        }`}
                        to="/covidhelpline/calls/td"
                      >
                        <i className="fas fa-arrow-right pt-1"></i>&nbsp;&nbsp;To Do
                      </Link>
                      <Link
                        className={`row btn btn-sm btn-block rounded-0 border-0 d-flex justify-content-start m-0 ${
                      pathname === '/covidhelpline/calls/ip'
                        ? 'btn-light disabled'
                        : 'btn-outline-light'
                        }`}
                        to="/covidhelpline/calls/ip"
                      >
                        <i className="fas fa-arrow-right pt-1"></i>&nbsp;&nbsp;In
                    Progress
                      </Link>
                    </div>
                  )}
                /></> : ''}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => {
                    logout();
                    history.replace('/covidhelpline/login');
                  }}
                >
                Logout
                </Link>
              </li>
            </>
          ) : (
            <>
              <li className="nav-item">
                <Link className="nav-link" to="/covidhelpline/login">
                  Login
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/covidhelpline/register">
                  Register
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(NavBar);

import {
  SET_LOGIN_STATUS,
  SET_USER_INFO,
  SET_TOKEN,
  LOGOUT,
  SET_ERROR_MESSAGE,
  SET_ALLOCATED_BENEFICIARIES,
  SET_STATE,
  SET_CALL_ALLOCATION_STATUS,
  SET_CALL_ALLOCATION,
  SET_LANGUAGES_STATUS,
  SET_LANGUAGES,
  SET_REGISTRATION_STATUS,
  SET_UPDATE_BENEFICIARY_STATUS,
  SET_FORM,
  CLEAR_ERROR_MESSAGE,
  SET_STATES_STATUS,
  SET_STATES,
  SET_USER_STATS,
  SET_WINDOW_DIMENSIONS,
  SET_SEND_RESET_LINK_STATUS,
  SET_RESET_PASSWORD_STATUS,
  SET_INITIATE_CALL_STATUS,
} from './types';
import initialState from '../../store/reducer/InitialState';

export default function (state = initialState.covidWorkflow, action) {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        user: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        formioToken: action.payload.FormioToken,
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        user: {
          callerName: '',
          states: [],
        },
        token: '',
        state: { id: '', name: '', status: false },
      };
    case SET_ALLOCATED_BENEFICIARIES:
      return {
        ...state,
        allocatedBeneficiaries: {
          ...action.payload,
          status: true,
        },
      };
    case SET_STATE:
      return {
        ...state,
        state: {
          ...action.payload,
          status: true,
        },
      };
    case SET_CALL_ALLOCATION_STATUS:
      return {
        ...state,
        callAllocation: {
          ...action.payload,
        },
      };
    case SET_CALL_ALLOCATION:
      return {
        ...state,
        callAllocation: action.payload.callAllocation,
        allocatedBeneficiaries: {
          toDo: state.allocatedBeneficiaries.toDo.concat(action.payload.toDo),
          inProgress: state.allocatedBeneficiaries.inProgress.concat(
            action.payload.inProgress,
          ),
          status: true,
        },
      };
    case SET_LANGUAGES_STATUS:
      return {
        ...state,
        languages: {
          status: action.payload,
        },
      };
    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case SET_REGISTRATION_STATUS:
      return {
        ...state,
        registrationStatus: action.payload,
      };
    case SET_UPDATE_BENEFICIARY_STATUS:
      return {
        ...state,
        allocatedBeneficiaries: {
          ...state.allocatedBeneficiaries,
          updateStatus: action.payload,
        },
      };
    case SET_FORM:
      return {
        ...state,
        form: action.payload,
      };
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        error: '',
      };
    case SET_STATES_STATUS:
      return {
        ...state,
        states: {
          status: action.payload,
        },
      };
    case SET_STATES:
      return {
        ...state,
        states: action.payload,
      };
    case SET_USER_STATS:
      return {
        ...state,
        stats: {
          ...state.stats,
          user: action.payload,
        },
      };
    case SET_WINDOW_DIMENSIONS:
      return {
        ...state,
        dimensions: action.payload,
      };
    case SET_SEND_RESET_LINK_STATUS:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          sendResetPasswordLinkStatus: action.payload,
        },
      };
    case SET_RESET_PASSWORD_STATUS:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          resetPasswordStatus: action.payload,
        },
      };
    case SET_INITIATE_CALL_STATUS:
      return {
        ...state,
        initiateCallStatus: action.payload,
      };
    default:
      return state;
  }
}

import Cookies from 'universal-cookie';

import {
  SET_LOGIN_STATUS,
  SET_USER_INFO,
  SET_TOKEN,
  SET_ERROR_MESSAGE,
  LOGOUT,
  SET_ALLOCATED_BENEFICIARIES,
  SET_STATE,
  SET_CALL_ALLOCATION_STATUS,
  SET_CALL_ALLOCATION,
  SET_LANGUAGES_STATUS,
  SET_LANGUAGES,
  SET_REGISTRATION_STATUS,
  SET_UPDATE_BENEFICIARY_STATUS,
  SET_FORM,
  CLEAR_ERROR_MESSAGE,
  SET_STATES_STATUS,
  SET_STATES,
  SET_USER_STATS,
  SET_WINDOW_DIMENSIONS,
  SET_SEND_RESET_LINK_STATUS,
  SET_RESET_PASSWORD_STATUS,
  SET_INITIATE_CALL_STATUS,
} from './types';

const cookies = new Cookies();

const baseUrl = `${process.env.REACT_APP_CMS_BACKEND_URL}/covidhelpline`;
const baseFormioUrl = `${process.env.REACT_APP_FORMIO_URL}`;

const getOptions = (
  method = 'GET',
  data = {},
  token = cookies.get('covidHelplineToken'),
) => {
  const options = {
    method,
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization_token: token,
    }),
  };
  if (!(method === 'GET')) {
    options.body = JSON.stringify(data);
  }
  return options;
};

const getListOfLanguages = () => (dispatch) => {
  const url = `${baseUrl}/languages`;
  fetch(url).then((response) => {
    response.json().then(({ data, error }) => {
      if (response.status === 200) {
        const { languages } = data;
        dispatch({
          type: SET_LANGUAGES,
          payload: { data: languages, status: response.status },
        });
      } else if (error) {
        dispatch({
          type: SET_LANGUAGES_STATUS,
          payload: response.status,
        });
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: error.message,
        });
      }
    });
  });
};

const getListOfStates = () => (dispatch) => {
  const url = `${baseUrl}/states`;
  fetch(url).then((response) => {
    response.json().then(({ data, error }) => {
      if (response.status === 200) {
        const { states } = data;
        dispatch({
          type: SET_STATES,
          payload: { data: states, status: response.status },
        });
      } else if (error) {
        dispatch({
          type: SET_STATES_STATUS,
          payload: response.status,
        });
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: error.message,
        });
      }
    });
  });
};

const register = params => (dispatch) => {
  const url = `${baseUrl}/register`;
  fetch(url, getOptions('POST', params)).then((response) => {
    dispatch({
      type: SET_REGISTRATION_STATUS,
      payload: response.status,
    });
    response.json().then(({ data, error }) => {
      if (response.status === 200) {
        const { caller, token, FormioToken } = data;
        dispatch({
          type: SET_USER_INFO,
          payload: caller,
        });
        dispatch({
          type: SET_TOKEN,
          payload: { token, FormioToken },
        });
        cookies.set('formioToken', FormioToken, {
          path: '/',
          maxAge: 86400,
        });
        cookies.set('covidHelplineToken', token, {
          path: '/',
          maxAge: 86400,
        });
        cookies.set('covidHelplineCaller', caller, {
          path: '/',
          maxAge: 86400,
        });
      } else if (error) {
        if (error.message) {
          dispatch({
            type: SET_ERROR_MESSAGE,
            payload: error.message,
          });
        } else {
          dispatch({
            type: SET_ERROR_MESSAGE,
            payload: 'Email is already registered',
          });
        }
      }
    });
  });
};

const login = ({ email, password }) => (dispatch) => {
  const url = `${baseUrl}/login`;
  fetch(url, getOptions('POST', { email, password })).then((response) => {
    dispatch({
      type: SET_LOGIN_STATUS,
      payload: response.status,
    });
    response.json().then(({ data, error }) => {
      if (response.status === 200) {
        const { caller, token, FormioToken } = data;
        dispatch({
          type: SET_USER_INFO,
          payload: caller,
        });
        dispatch({
          type: SET_TOKEN,
          payload: { token, FormioToken },
        });
        cookies.set('formioToken', FormioToken, {
          path: '/',
          maxAge: 86400,
        });
        cookies.set('covidHelplineToken', token, {
          path: '/',
          maxAge: 86400,
        });
        cookies.set('covidHelplineCaller', caller, {
          path: '/',
          maxAge: 86400,
        });
      } else if (error) {
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: error.message,
        });
      }
    });
  });
};

const logout = () => (dispatch) => {
  cookies.remove('covidHelplineToken', { path: '/' });
  cookies.remove('covidHelplineCaller', { path: '/' });
  cookies.remove('covidHelplineState', { path: '/' });
  dispatch({
    type: LOGOUT,
    payload: '',
  });
};

const getAllocatedCalls = (state, token) => (dispatch) => {
  const url = new URL(`${baseUrl}/beneficiaries/allocated`);
  if (state) {
    url.search = new URLSearchParams({ state }).toString();
  }
  fetch(url, getOptions('GET', {}, token)).then((response) => {
    response.json().then(({ data, error }) => {
      if (response.status === 200) {
        dispatch({
          type: SET_ALLOCATED_BENEFICIARIES,
          payload: {
            toDo: data.beneficiaries.filter(
              beneficiary => beneficiary.beneficiaryData === null,
            ),
            inProgress: data.beneficiaries.filter(
              beneficiary => beneficiary.beneficiaryData !== null,
            ),
          },
        });
      } else if (error) {
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: error.message,
        });
      }
    });
  });
};

const autoAllocateCalls = (number, state) => (dispatch) => {
  const url = new URL(`${baseUrl}/beneficiaries/autoallocate`);
  dispatch({
    type: SET_CALL_ALLOCATION_STATUS,
    payload: { status: 'pending' },
  });
  fetch(
    url,
    getOptions('POST', { number, state }, cookies.get('covidHelplineToken')),
  ).then((response) => {
    response.json().then(({ data, error }) => {
      if (response.status === 200) {
        dispatch({
          type: SET_CALL_ALLOCATION,
          payload: {
            toDo: data.beneficiaries.filter(
              beneficiary => beneficiary.beneficiaryData === null,
            ),
            inProgress: data.beneficiaries.filter(
              beneficiary => beneficiary.beneficiaryData !== null,
            ),
            callAllocation: {
              status: 'success',
              message: `${data.beneficiaries.length} new call(s) allocated. `,
            },
          },
        });
      } else if (error) {
        dispatch({
          type: SET_CALL_ALLOCATION_STATUS,
          payload: { status: 'error', message: error.message },
        });
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: error.message,
        });
      }
    });
  });
};

const selectState = (key, value) => (dispatch) => {
  dispatch({
    type: SET_STATE,
    payload: value,
  });
  cookies.set('covidHelplineState', value, {
    path: '/',
    maxAge: 86400,
  });
};

const restoreCookies = () => (dispatch) => {
  if (cookies.get('covidHelplineToken')) {
    const caller = cookies.get('covidHelplineCaller');
    dispatch({
      type: SET_USER_INFO,
      payload: caller,
    });
    dispatch({
      type: SET_TOKEN,
      payload: {
        token: cookies.get('covidHelplineToken'),
        formioToken: cookies.get('formioToken'),
      },
    });
    dispatch({
      type: SET_STATE,
      payload: cookies.get('covidHelplineState'),
    });
  }
};

const updateBeneficiary = (
  beneficiaryId,
  submissionId,
  status,
  callbackDate,
) => (dispatch) => {
  const url = new URL(
    `${baseUrl}/beneficiaries`,
  );
  const data = { beneficiaryId, submissionId, status };
  if (callbackDate) {
    data.callbackDate = callbackDate;
  }
  fetch(url, getOptions('PATCH', data))
    .then(() => {
      dispatch({
        type: SET_UPDATE_BENEFICIARY_STATUS,
        payload: 'done',
      });
    });
};

const getFormByAlias = alias => (dispatch) => {
  const url = new URL(`${baseFormioUrl}/${alias}`);
  fetch(url, {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-jwt-token': cookies.get('formioToken'),
    }),
  })
    .then(response => response.json())
    .then((form) => {
      dispatch({
        type: SET_FORM,
        payload: form,
      });
    });
};

const initiateCall = beneficiary => (dispatch) => {
  const url = new URL(`${baseUrl}/beneficiaries/${beneficiary}/connect`);
  fetch(url, getOptions('POST'))
    .then((response) => {
      if (response.status < 400) {
        dispatch({
          type: SET_INITIATE_CALL_STATUS,
          payload: 'success',
        });
      } else {
        dispatch({
          type: SET_INITIATE_CALL_STATUS,
          payload: 'fail',
        });
      }
    });
};

const clearErrorMessages = () => (dispatch) => {
  dispatch({
    type: CLEAR_ERROR_MESSAGE,
  });
};

const getUserStats = (email, states) => (dispatch) => {
  const url = new URL(`${baseUrl}/statusReport`);
  const search = new URLSearchParams();
  search.append('callers[]', email);
  if (states) {
    states.forEach((state) => {
      search.append('states[]', state);
    });
  }
  url.search = search.toString();
  fetch(url, getOptions())
    .then(response => response.json())
    .then(body => dispatch({
      type: SET_USER_STATS,
      payload: Object.entries(body.data).map(([stateName, user]) => ({
        stateName,
        status: Object.values(user)[0],
      })),
    }));
};

const updateDimensions = (width, height) => (dispatch) => {
  dispatch({
    type: SET_WINDOW_DIMENSIONS,
    payload: { width, height },
  });
};

const sendPasswordRecoveryEmail = email => (dispatch) => {
  const url = new URL(`${baseUrl}/sendpasswordrecoveryemail`);
  dispatch({
    type: SET_SEND_RESET_LINK_STATUS,
    payload: {
      pending: true,
    },
  });
  fetch(url, getOptions('POST', { email }))
    .then(response => response.json())
    .then(({ data, error }) => {
      if (data) {
        dispatch({
          type: SET_SEND_RESET_LINK_STATUS,
          payload: {
            message: data.message,
            error: '',
          },
        });
      } else {
        dispatch({
          type: SET_SEND_RESET_LINK_STATUS,
          payload: {
            message: '',
            error: error.message,
          },
        });
      }
    });
};

const resetPassword = (token, password) => (dispatch) => {
  const url = new URL(`${baseUrl}/resetpassword`);
  dispatch({
    type: SET_RESET_PASSWORD_STATUS,
    payload: {
      pending: true,
    },
  });
  fetch(url, getOptions('PUT', { password }, token))
    .then(response => response.json())
    .then(({ data, error }) => {
      if (data) {
        dispatch({
          type: SET_RESET_PASSWORD_STATUS,
          payload: { message: data.message, error: '' },
        });
      } else {
        dispatch({
          type: SET_RESET_PASSWORD_STATUS,
          pauload: { message: '', error: error.message },
        });
      }
    });
};

export default {
  getListOfLanguages,
  getListOfStates,
  register,
  login,
  logout,
  getAllocatedCalls,
  autoAllocateCalls,
  selectState,
  restoreCookies,
  updateBeneficiary,
  getFormByAlias,
  initiateCall,
  clearErrorMessages,
  getUserStats,
  updateDimensions,
  sendPasswordRecoveryEmail,
  resetPassword,
};

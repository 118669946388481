import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

class Modals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      large: false,
      small: false,
      primary: false,
      success: false,
      warning: false,
      danger: false,
      info: false,
    };
  }

  toggle = () => {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  };

  toggleLarge = () => {
    const { large } = this.state;
    this.setState({
      large: !large,
    });
  };

  toggleSmall = () => {
    const { small } = this.state;
    this.setState({
      small: !small,
    });
  };

  togglePrimary = () => {
    const { primary } = this.state;
    this.setState({
      primary: !primary,
    });
  };

  toggleSuccess = () => {
    const { success } = this.state;
    this.setState({
      success: !success,
    });
  };

  toggleWarning = () => {
    const { warning } = this.state;
    this.setState({
      warning: !warning,
    });
  };

  toggleDanger = () => {
    const { danger } = this.state;
    this.setState({
      danger: !danger,
    });
  };

  toggleInfo = () => {
    const { info } = this.state;
    this.setState({
      info: !info,
    });
  };

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify" /> Bootstrap Modals
              </CardHeader>
              <CardBody>
                <Button onClick={this.toggle} className="mr-1">
                  Launch demo modal
                </Button>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className={this.props.className}
                >
                  <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
                  <ModalBody>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>
                      Do Something
                    </Button>{' '}
                    <Button color="secondary" onClick={this.toggle}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>

                <Button onClick={this.toggleLarge} className="mr-1">
                  Launch large modal
                </Button>
                <Modal
                  isOpen={this.state.large}
                  toggle={this.toggleLarge}
                  className={`modal-lg ${this.props.className}`}
                >
                  <ModalHeader toggle={this.toggleLarge}>
                    Modal title
                  </ModalHeader>
                  <ModalBody>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.toggleLarge}>
                      Do Something
                    </Button>{' '}
                    <Button color="secondary" onClick={this.toggleLarge}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>

                <Button onClick={this.toggleSmall} className="mr-1">
                  Launch small modal
                </Button>
                <Modal
                  isOpen={this.state.small}
                  toggle={this.toggleSmall}
                  className={`modal-sm ${this.props.className}`}
                >
                  <ModalHeader toggle={this.toggleSmall}>
                    Modal title
                  </ModalHeader>
                  <ModalBody>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.toggleSmall}>
                      Do Something
                    </Button>{' '}
                    <Button color="secondary" onClick={this.toggleSmall}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>

                <hr />

                <Button
                  color="primary"
                  onClick={this.togglePrimary}
                  className="mr-1"
                >
                  Primary modal
                </Button>
                <Modal
                  isOpen={this.state.primary}
                  toggle={this.togglePrimary}
                  className={`modal-primary ${this.props.className}`}
                >
                  <ModalHeader toggle={this.togglePrimary}>
                    Modal title
                  </ModalHeader>
                  <ModalBody>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.togglePrimary}>
                      Do Something
                    </Button>{' '}
                    <Button color="secondary" onClick={this.togglePrimary}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>

                <Button
                  color="success"
                  onClick={this.toggleSuccess}
                  className="mr-1"
                >
                  Success modal
                </Button>
                <Modal
                  isOpen={this.state.success}
                  toggle={this.toggleSuccess}
                  className={`modal-success ${this.props.className}`}
                >
                  <ModalHeader toggle={this.toggleSuccess}>
                    Modal title
                  </ModalHeader>
                  <ModalBody>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </ModalBody>
                  <ModalFooter>
                    <Button color="success" onClick={this.toggleSuccess}>
                      Do Something
                    </Button>{' '}
                    <Button color="secondary" onClick={this.toggleSuccess}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>

                <Button
                  color="warning"
                  onClick={this.toggleWarning}
                  className="mr-1"
                >
                  Warning modal
                </Button>
                <Modal
                  isOpen={this.state.warning}
                  toggle={this.toggleWarning}
                  className={`modal-warning ${this.props.className}`}
                >
                  <ModalHeader toggle={this.toggleWarning}>
                    Modal title
                  </ModalHeader>
                  <ModalBody>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </ModalBody>
                  <ModalFooter>
                    <Button color="warning" onClick={this.toggleWarning}>
                      Do Something
                    </Button>{' '}
                    <Button color="secondary" onClick={this.toggleWarning}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>

                <Button
                  color="danger"
                  onClick={this.toggleDanger}
                  className="mr-1"
                >
                  Danger modal
                </Button>
                <Modal
                  isOpen={this.state.danger}
                  toggle={this.toggleDanger}
                  className={`modal-danger ${this.props.className}`}
                >
                  <ModalHeader toggle={this.toggleDanger}>
                    Modal title
                  </ModalHeader>
                  <ModalBody>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" onClick={this.toggleDanger}>
                      Do Something
                    </Button>{' '}
                    <Button color="secondary" onClick={this.toggleDanger}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>

                <Button color="info" onClick={this.toggleInfo} className="mr-1">
                  Info modal
                </Button>
                <Modal
                  isOpen={this.state.info}
                  toggle={this.toggleInfo}
                  className={`modal-info ${this.props.className}`}
                >
                  <ModalHeader toggle={this.toggleInfo}>
                    Modal title
                  </ModalHeader>
                  <ModalBody>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.toggleInfo}>
                      Do Something
                    </Button>{' '}
                    <Button color="secondary" onClick={this.toggleInfo}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Modals;

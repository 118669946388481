import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from './Sidebar';
import Calls from './Calls';
import Statistics from './Statistics';

const WorkSpace = function (props) {
  const {
    user: { callerName: userName },
    width,
  } = props;
  return (
    <>
      <div className="row">
        {width > 480
          ? <div className="col col-xs-4 col-md-3 pr-0">
            <Sidebar />
          </div> : ''}
        <div className="col col-xs-8 col-md-9 px-3">
          <main
            className="bg-white"
            style={{
              minHeight: 'calc(100vh - 56px)',
            }}
          >
            <Switch>
              <Route
                exact
                path="/covidhelpline"
                render={() => (
                  <div className="container-fluid pt-3">
                    <div className="card ">
                      <div className="card-header">
                        <h2 className="text-center">
                          {`Welcome ${userName && userName.split(' ')[0]} to
                            Indus Action's Covid Helpline platform`}
                        </h2>
                      </div>
                      <div className="card-body">
                        <p>This page is currently under construction.</p>
                        <p>
                          {`Click on "Calls" in the sidebar to go to the calls
                            section.`}
                        </p>
                        <p>
                          <strong>NEW: </strong>{`Click on "Statictics" to see the various numbers related to your calls.`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              />
              <Route path="/covidhelpline/calls" component={Calls} />
              <Route path="/covidhelpline/stats" component={Statistics} />
            </Switch>
          </main>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  width: state.covidWorkflow.dimensions.width,
});

export default connect(mapStateToProps)(WorkSpace);

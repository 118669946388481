import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import CallsToDo from './CallsToDo';
import CallsInProgress from './CallsInProgress';
import actions from '../store/actions';
import FilteredDropdown from './FilteredDropdown';
import FormRenderer from './FormRenderer';

const cookies = new Cookies();

class Calls extends Component {
  componentDidMount() {
    const {
      getFormByAlias,
      token,
      selectedState: { id },
      getAllocatedCalls,
    } = this.props;
    getFormByAlias('corona/calling');
    if (id) {
      getAllocatedCalls(id, token || cookies.get('covidHelplineToken'));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getAllocatedCalls,
      token,
      selectedState: { id },
    } = this.props;
    if (id && id !== prevProps.selectedState.id) {
      getAllocatedCalls(id, token || cookies.get('covidHelplineToken'));
    }
    return null;
  }

  render() {
    const {
      beneficiaries, states, selectedState, selectState,
    } = this.props;
    const { toDo, inProgress } = beneficiaries;
    return (
      <div className="container pt-3">
        <div
          className="w-100 border border-warning mb-2"
          style={{
            height: '35px',
          }}
        >
          <FilteredDropdown
            name="state"
            selectedItem={selectedState}
            list={states.map(({ id, stateName }) => ({ id, name: stateName }))}
            select={selectState}
            color="white"
          />
        </div>
        <Switch>
          <Route
            exact
            path="/covidhelpline/calls"
            render={() => (
              <div className="card">
                <div className="card-header">
                  <h4>Summary</h4>
                </div>
                <div className="card-body">
                  {selectedState.id
                    ? `You have ${toDo.length} Call(s) not done and ${inProgress.length} Call(s) in
                  progress.`
                    : `Please select a State to start.`}
                </div>
              </div>
            )}
          />
          <Route path="/covidhelpline/calls/td" component={CallsToDo} />
          <Route path="/covidhelpline/calls/ip" component={CallsInProgress} />
          <Route path="/covidhelpline/calls/form" component={FormRenderer} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  beneficiaries: state.covidWorkflow.allocatedBeneficiaries,
  token: state.covidWorkflow.token,
  states: state.covidWorkflow.user.states,
  selectedState: state.covidWorkflow.state,
});

export default connect(mapStateToProps, {
  getAllocatedCalls: actions.getAllocatedCalls,
  getFormByAlias: actions.getFormByAlias,
  selectState: actions.selectState,
})(Calls);

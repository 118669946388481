import Cookies from 'universal-cookie';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import NavBar from './components/NavBar';
import Register from './components/Register';
import WorkSpace from './components/WorkSpace';

import actions from './store/actions';
import ResetPassword from './components/ResetPassword';

const cookies = new Cookies();

class CovidWorkflow extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.update);
    const { restoreCookies } = this.props;
    restoreCookies();
  }

  shouldComponentUpdate(nextProps) {
    const { token: oldToken, history } = this.props;
    if (
      history.location.pathname === '/covidhelpline/login'
      || history.location.pathname === '/covidhelpline/register'
    ) {
      if (oldToken !== nextProps.token) {
        history.push('/covidhelpline');
      }
    }
    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update);
  }

  update = () => {
    const { updateDimensions } = this.props;
    updateDimensions(window.innerWidth, window.innerHeight);
  };


  render() {
    let { token } = this.props;
    token = token || cookies.get('covidHelplineToken');
    const { logout, user } = this.props;
    return (
      <div className="container-sm p-0">
        <NavBar logout={logout} token={token}/>
        <Switch>
          <Route path="/covidhelpline/login" name="Login" component={Login} />
          <Route
            path="/covidhelpline/register"
            name="Register"
            component={Register}
          />
          <Route path="/covidhelpline/forgotpassword" name="Forgot Password" component={ForgotPassword}/>
          <Route path="/covidhelpline/resetpassword" name="Reset Password" component={ResetPassword}/>
          <Route path="/covidhelpline">
            {token ? (
              <WorkSpace user={user} token={token}/>
            ) : (
              <Redirect to="/covidhelpline/login" />
            )}
          </Route>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.covidWorkflow.token,
  user: state.covidWorkflow.user,
});

export default connect(mapStateToProps, {
  logout: actions.logout,
  restoreCookies: actions.restoreCookies,
  updateDimensions: actions.updateDimensions,
})(CovidWorkflow);

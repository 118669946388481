import React, { Component } from 'react';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  FormGroup,
} from 'reactstrap';

class Username extends Component {
  constructor(props) {
    super(props);
    const { required } = this.props;
    this.state = {
      validate: {},
      required,
    };
  }

  onChange = (e) => {
    const { onChange } = this.props;
    this.validate(e);
    onChange(e);
  };

  validate = (e) => {
    const { validate, required } = this.state;
    const { onValidation } = this.props;
    const { name } = e.target;
    if (!e.target.value && required) {
      validate[name] = 'empty';
    } else {
      validate[name] = 'success';
    }
    const state = validate[name] === 'success';
    onValidation(name, state);
    this.setState({ validate });
  };

  render() {
    const { submit, name, placeholder } = this.props;
    const { validate } = this.state;
    const username = validate[name];
    return (
      <FormGroup>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-user" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            name={name}
            placeholder={placeholder}
            onChange={this.onChange}
            type="text"
            autoComplete="Name"
            valid={username === 'success'}
            invalid={username === 'empty' || submit}
          />
          {/* <FormFeedback invalid="true">Username cannot be empty</FormFeedback> */}
          <FormFeedback data-test="name_error" invalid="true">
            {username === 'empty' || submit
              ? 'Name cannot be empty!  '
              : 'Name at-least Four Character '}
          </FormFeedback>
        </InputGroup>
      </FormGroup>
    );
  }
}

export default Username;

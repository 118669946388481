import {
  USERMANAGEMENT_USERS,
  USERMANAGEMENT_USERS_STATUS,
  USERMANAGEMENT_FEATURES,
  USERMANAGEMENT_FEATURES_STATUS,
  USERMANAGEMENT_PERMISSIONS,
  USERMANAGEMENT_PERMISSIONS_STATUS,
  USERMANAGEMENT_STATES,
  USERMANAGEMENT_STATES_STATUS,
  USERMANAGEMENT_SEARCHSTATE,
  USERMANAGEMENT_SEARCHSTATE_STATUS,
  USERMANAGEMENT_ADDPERMISSION,
  USERMANAGEMENT_ADDPERMISSION_STATUS,
  USERMANAGEMENT_UPDATEPERMISSION,
  USERMANAGEMENT_UPDATEPERMISSION_STATUS,
  USERMANAGEMENT_USER_PERMISSIONS,
} from '../action/Types';
import initialState from './InitialState';

export default function (state = initialState.userMangement, action) {
  switch (action.type) {
    case USERMANAGEMENT_USERS_STATUS:
      return {
        ...state,
        users: { ...state.users, status: action.payload },
      };
    case USERMANAGEMENT_USERS:
      return {
        ...state,
        users: { ...state.users, ...action.payload },
      };
    case USERMANAGEMENT_FEATURES_STATUS:
      return {
        ...state,
        features: { ...state.features, status: action.payload },
      };
    case USERMANAGEMENT_FEATURES:
      return {
        ...state,
        features: { ...state.features, data: action.payload },
      };
    case USERMANAGEMENT_PERMISSIONS_STATUS:
      return {
        ...state,
        all_permissions: { ...state.all_permissions, status: action.payload },
      };
    case USERMANAGEMENT_PERMISSIONS:
      return {
        ...state,
        all_permissions: { ...state.all_permissions, data: action.payload },
      };
    case USERMANAGEMENT_STATES_STATUS:
      return {
        ...state,
        states: { ...state.states, status: action.payload },
      };
    case USERMANAGEMENT_STATES:
      return {
        ...state,
        states: { ...state.states, data: action.payload },
      };
    case USERMANAGEMENT_SEARCHSTATE_STATUS:
      return {
        ...state,
        search: { ...state.search, status: action.payload },
      };
    case USERMANAGEMENT_SEARCHSTATE:
      return {
        ...state,
        search: { ...state.search, ...action.payload },
      };
    case USERMANAGEMENT_ADDPERMISSION_STATUS:
      return {
        ...state,
        data: { ...state.data, status: action.payload },
      };
    case USERMANAGEMENT_ADDPERMISSION:
      return {
        ...state,
        data: { ...state.data, data: action.payload },
      };
    case USERMANAGEMENT_UPDATEPERMISSION_STATUS:
      return {
        ...state,
        data: { ...state.data, status: action.payload },
      };
    case USERMANAGEMENT_UPDATEPERMISSION:
      return {
        ...state,
        data: { ...state.data, data: action.payload },
      };
    case USERMANAGEMENT_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload,
      };
    default:
      return state;
  }
}

import {
  TASKMANAGEMENT_ADDTYPE,
  TASKMANAGEMENT_ADDTYPE_STATUS,
  TASKMANAGEMENT_ALLTYPE,
  TASKMANAGEMENT_ALLTYPE_STATUS,
  TASKMANAGEMENT_ADDSTATUS,
  TASKMANAGEMENT_ADDSTATUS_STATUS,
  TASKMANAGEMENT_ALLSTATUS,
  TASKMANAGEMENT_ALLSTATUS_STATUS,
  TASKMANAGEMENT_ALLCYCLE,
  TASKMANAGEMENT_ALLCYCLE_STATUS,
  TASKMANAGEMENT_ADDMESSAGE,
  TASKMANAGEMENT_ADDMESSAGE_STATUS,
  TASKMANAGEMENT_ALLMESSAGE,
  TASKMANAGEMENT_ALLMESSAGE_STATUS,
  TASKMANAGEMENT_ALLCALLERS,
  TASKMANAGEMENT_ALLCALLERS_STATUS,
} from '../action/Types';
import initialState from './InitialState';

export default function (state = initialState.taskManagement, action) {
  switch (action.type) {
    case TASKMANAGEMENT_ADDTYPE_STATUS:
      return {
        ...state,
        addTask: { ...state.addTask, status: action.payload },
      };
    case TASKMANAGEMENT_ADDTYPE:
      return {
        ...state,
        addTask: { ...state.addTask, data: action.payload },
      };
    case TASKMANAGEMENT_ALLTYPE_STATUS:
      return {
        ...state,
        allTasks: { ...state.allTasks, status: action.payload },
      };
    case TASKMANAGEMENT_ALLTYPE:
      return {
        ...state,
        allTasks: { ...state.allTasks, data: action.payload },
      };
    case TASKMANAGEMENT_ADDSTATUS_STATUS:
      return {
        ...state,
        addTaskStatus: { ...state.addTaskStatus, status: action.payload },
      };
    case TASKMANAGEMENT_ADDSTATUS:
      return {
        ...state,
        addTaskStatus: { ...state.addTaskStatus, data: action.payload },
      };
    case TASKMANAGEMENT_ALLSTATUS_STATUS:
      return {
        ...state,
        allTaskStatus: { ...state.allTaskStatus, status: action.payload },
      };
    case TASKMANAGEMENT_ALLSTATUS:
      return {
        ...state,
        allTaskStatus: { ...state.allTaskStatus, data: action.payload },
      };
    case TASKMANAGEMENT_ALLCYCLE_STATUS:
      return {
        ...state,
        allCycle: { ...state.allCycle, status: action.payload },
      };
    case TASKMANAGEMENT_ALLCYCLE:
      return {
        ...state,
        allCycle: { ...state.allCycle, data: action.payload },
      };
    case TASKMANAGEMENT_ADDMESSAGE_STATUS:
      return {
        ...state,
        addMessage: { ...state.addMessage, status: action.payload },
      };
    case TASKMANAGEMENT_ADDMESSAGE:
      return {
        ...state,
        addMessage: { ...state.addMessage, data: action.payload },
      };
    case TASKMANAGEMENT_ALLMESSAGE_STATUS:
      return {
        ...state,
        allMessage: { ...state.allMessage, status: action.payload },
      };
    case TASKMANAGEMENT_ALLMESSAGE:
      return {
        ...state,
        allMessage: { ...state.allMessage, data: action.payload },
      };
    case TASKMANAGEMENT_ALLCALLERS_STATUS:
      return {
        ...state,
        allCallers: { ...state.allCallers, status: action.payload },
      };
    case TASKMANAGEMENT_ALLCALLERS:
      return {
        ...state,
        allCallers: { ...state.allCallers, data: action.payload },
      };
    default:
      return state;
  }
}

import React, { Component } from 'react';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  FormGroup,
  FormText,
} from 'reactstrap';

class Email extends Component {
  constructor(props) {
    super(props);
    const { required } = this.props;
    this.state = {
      validate: {},
      // eslint-disable-next-line react/no-unused-state
      required: [required],
      margin: required ? 'mb-3' : 'mb-1',
    };
  }

  onChange = (e) => {
    const { onChange } = this.props;
    this.validate(e);
    onChange(e);
  };

  validate = (e) => {
    // eslint-disable-next-line
    const emailRex = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    const { validate } = this.state;
    const { onValidation, required } = this.props;
    const { name } = e.target;
    if (!e.target.value) {
      if (required) {
        validate[name] = 'empty';
      } else {
        validate[name] = 'success';
      }
    } else if (emailRex.test(e.target.value)) {
      validate[name] = 'success';
    } else {
      validate[name] = 'danger';
    }
    const state = validate[name] === 'success';
    onValidation(name, state);
    this.setState({ validate });
  };

  render() {
    const { submit, name, placeholder } = this.props;
    const { validate, margin, required } = this.state;
    const email = validate[name];
    return (
      <FormGroup>
        <InputGroup className={margin}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>@</InputGroupText>
          </InputGroupAddon>
          <Input
            name={name}
            placeholder={placeholder}
            onChange={this.onChange}
            type="text"
            autoComplete="email"
            valid={email === 'success'}
            invalid={email === 'danger' || email === 'empty' || submit}
          />
          <FormFeedback data-test="email_error" invalid="true">
            {email === 'empty' || submit
              ? 'Email cannot be empty'
              : 'Email format is incorrect. Correct format: someone@example.com'}
          </FormFeedback>
        </InputGroup>
        {required.map((e) => {
          if (!e) {
            return <FormText key="notRequired">Email is optional</FormText>;
          }
          return null;
        })}
      </FormGroup>
    );
  }
}

export default Email;

import React, { Component, lazy, Suspense } from 'react';
import Cookies from 'universal-cookie';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux';
import {
  Card,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Col,
  Row
} from 'reactstrap';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

import { stateTaskCount } from '../../store/action/Dashboard';
import { getMissedCallCount } from '../../store/action/MissedCallExotel';
import {
  createTable,
  heding,
  // CallSum,
  calculatesumofcalls
} from '../../helper/StatTable';
import StatsCard from '../statscard/StatsCard';
import DateFnsUtils from '@date-io/date-fns';
const cookies = new Cookies();

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: '',
      to: ''
    };
  }

  componentDidMount = () => {
    const { stateTaskCount, state, getMissedCallCount } = this.props;
    stateTaskCount({ state: state.id || cookies.get('state').id });
    getMissedCallCount();
  };

  getSnapshotBeforeUpdate(prevProps) {
    const { stateTaskCount, state } = this.props;
    if (prevProps.state.name !== state.name) {
      stateTaskCount({ state: state.id });
      return null;
    }
    return null;
  }

  handleDateChange = async event => {
    await this.setState({ from: event });
    const { ...action } = this.props;
    const { from, to } = this.state;

    const param = {
      from: from !== null ? new Date(from).toJSON() : '',
      to: to !== null ? new Date(to).toJSON() : ''
    };
    action.getMissedCallCount(param);
  };

  handleEndDateChange = async event => {
    await this.setState({ to: event });
    const { ...action } = this.props;
    const { from, to } = this.state;

    const param = {
      from: from !== null ? new Date(from).toJSON() : '',
      to: to !== null ? new Date(to).toJSON() : ''
    };
    action.getMissedCallCount(param);
  };

  render() {
    const { stateTotalCount, exotelCallCount } = this.props;

    const { from, to } = this.state;
    var singalstate = [];
    singalstate.push({
      name: this.props.LocationName,
      data: stateTotalCount.data.data
    });
    const {
      totalToDo,
      totalInProgrees,
      totalDone,
      totalCalls
    } = calculatesumofcalls(singalstate);

    return (
      <div className="animated fadeIn">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <StatsCard
              icon="fa fa-database"
              title="TO DO"
              value={totalToDo}
              color="red"
            />
          </Grid>
          <Grid item xs={4}>
            <StatsCard
              icon="fa fa-cogs"
              title="IN PROGRESS"
              value={totalInProgrees}
              color="rgb(255, 129, 0)"
            />
          </Grid>
          <Grid item xs={4}>
            <StatsCard
              icon="fa fa-check-circle"
              title="COMPLETED"
              value={totalDone}
              color="rgb(45, 167, 105)"
            />
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Card
            style={{
              width: '100%',

              padding: '32px',
              marginTop: ' 50px'
            }}
          >
            <Typography style={{ fontSize: '14' }} color="textSecondary">
              Call Records
            </Typography>
            <Table responsive>
              <thead
                style={{
                  fontWeight: 'bold'
                }}
              >
                {heding(stateTotalCount.data.data)}
              </thead>
              <tbody>{createTable(stateTotalCount.data.data)}</tbody>
            </Table>
          </Card>
        </Grid>

        <Grid container item xs={12}>
          <Card
            style={{
              height: '250px',
              width: '100%',
              padding: '25px'
            }}
          >
            <Typography gutterBottom variant="h5">
              <h3
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: 'x-large'
                }}
              >
                EXOTEL MISSEDCALL
              </h3>
            </Typography>

            <Row>
              <Col sm="5" style={{ marginLeft: '27px' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    disableFuture
                    minDate={from || '01/01/2000'}
                    maxDate={to || new Date().toLocaleDateString().split('/')}
                    style={{ width: '100%' }}
                    variant="inline"
                    label="Select Start Date"
                    value={from !== '' ? from : null}
                    onChange={this.handleDateChange}
                    format="MM/dd/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </Col>
              <Col sm="5" style={{ marginLeft: '82px' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    disableFuture
                    maxDate={to || new Date().toLocaleDateString().split('/')}
                    minDate={from || '01/01/2000'}
                    style={{ width: '100%' }}
                    variant="inline"
                    label="Select End Date"
                    value={to !== '' ? to : null}
                    onChange={this.handleEndDateChange}
                    format="MM/dd/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </Col>
            </Row>
            <div
              style={{
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: 'x-large',
                marginTop: '32px',
                marginLeft: '27px'
              }}
            >
              Total Missed Calls:{' '}
              {exotelCallCount.data.length !== 0
                ? exotelCallCount.data.data.totalmissedcall
                : 0}
              <br></br>
              Total Unique Calls:{' '}
              {exotelCallCount.data.length !== 0
                ? exotelCallCount.data.data.uniquecalls
                : 0}
            </div>
          </Card>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    stateTotalCount: state.dashboard.stateTotalCount,
    state: state.user.state,
    exotelCallCount: state.exotel.exotelCallCount
  };
}

export default connect(mapStateToProps, {
  stateTaskCount,
  getMissedCallCount
})(Dashboard);

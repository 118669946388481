import Cookies from 'universal-cookie';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
const cookies = new Cookies();

class Dashboard {
  static TaskCount = data => fetch(
      `${baseUrl}/v2.0/task/count/${
        data.state_id ? `?state_id=${data.state_id}` : ``
      }${data.assignee && data.state_id ? `&assignee=${data.assignee}` : ``}${
        data.from ? `&from=${data.from}` : ``
      }${data.to ? `&to=${data.to}` : ``}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json, text/plain, */*',
          authorization_token: cookies.get('token'),
        },
      },
  );
}

export default Dashboard;

import { isNull } from 'util';
import {
  FORMIO_FORM,
  FORMIO_MESSAGE,
  FORMIO_MESSAGE_TYPE,
  // FORMIO_FORM_LIST,
  FORMIO_SUBMISSION_LIST,
  FORMIO_SUBMISSION,
  FORMIO_ADDL_FORM_DATA,
  FORMIO_ADDL_FORM_DATA_LIST,
  FORMIO_NEWSUBMISSION_STATUS,
  FORMIO_NEWSUBMISSION,
  FORMIO_SYNCAPI,
  FORMIO_SYNCAPI_STATUS,
  FORMIO_FORM_CREATE_STSTUS,
  FORMIO_FORM_CREATE,
  FORMIO_FORM_LIST_STATUS,
  FORMIO_FORM_LIST,
  FORMIO_DATADOWN_STATUS,
  FORMIO_DATADOWN,
  FORMIO_PERSISTENCE_EXISTENCE,
} from '../action/Types';
import initialState from './InitialState';

export default function (state = initialState.formio, action) {
  const oldState = { ...state };
  switch (action.type) {
    case FORMIO_PERSISTENCE_EXISTENCE:
      return {
        ...state,
        persistenceExistence: action.payload,
      };
    case FORMIO_FORM_CREATE_STSTUS:
      return {
        ...state,
        createForm: { ...state.createForm, status: action.payload },
      };
    case FORMIO_FORM_CREATE:
      return {
        ...state,
        createForm: { ...state.createForm, data: action.payload },
      };

    case FORMIO_FORM_LIST_STATUS:
      return {
        ...state,
        formList: { ...state.formList, status: action.payload },
      };
    case FORMIO_FORM_LIST:
      return {
        ...state,
        formList: { ...state.formList, data: action.payload },
      };

    case FORMIO_FORM:
      if (isNull(action.payload)) {
        delete oldState.form;
        return oldState;
      }
      return {
        ...state,
        form: action.payload,
      };
      // case FORMIO_FORM_LIST:
      //   return {
      //     ...state,
      //     formList: action.payload,
      //   };

    case FORMIO_ADDL_FORM_DATA:
      if (isNull(action.payload)) {
        delete oldState.addlFormData;
        return oldState;
      }
      return {
        ...state,
        addlFormData: action.payload,
      };
    case FORMIO_ADDL_FORM_DATA_LIST:
      return {
        ...state,
        addlFormDataList: action.payload,
      };
    case FORMIO_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case FORMIO_MESSAGE_TYPE:
      return {
        ...state,
        messageType: action.payload,
      };
    case FORMIO_SUBMISSION_LIST:
      return {
        ...state,
        submissionList: action.payload,
      };
    case FORMIO_SUBMISSION:
      return {
        ...state,
        submission: action.payload,
      };
    case FORMIO_NEWSUBMISSION_STATUS:
      return {
        ...state,
        newSubmission: { ...state.newSubmission, status: action.payload },
      };
    case FORMIO_NEWSUBMISSION:
      return {
        ...state,
        newSubmission: { ...state.newSubmission, data: action.payload },
      };
    case FORMIO_SYNCAPI_STATUS:
      return {
        ...state,
        syncapi: { ...state.syncapi, status: action.payload },
      };
    case FORMIO_SYNCAPI:
      return {
        ...state,
        syncapi: { ...state.syncapi, data: action.payload },
      };
    case FORMIO_DATADOWN_STATUS:
      return {
        ...state,
        datadown: { ...state.datadown, status: action.payload },
      };
    case FORMIO_DATADOWN:
      return {
        ...state,
        datadown: { ...state.datadown, data: action.payload },
      };
    default:
      return state;
  }
}

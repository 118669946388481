import React, { Component } from 'react';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  FormGroup,
  // FormText,
} from 'reactstrap';

class UserPassword extends Component {
  constructor(props) {
    super(props);
    const { required } = this.props;
    this.state = {
      validate: {},
      // eslint-disable-next-line react/no-unused-state
      required: [required],
      margin: required ? 'mb-3' : 'mb-1',
    };
  }

  onChange = (e) => {
    const { onChange } = this.props;
    this.validate(e);
    onChange(e);
  };

  validate = (e) => {
    const passwordRex = /^.{6,}$/;
    const { validate } = this.state;
    const { name } = e.target;
    const { onValidation } = this.props;
    if (!e.target.value) {
      validate[name] = 'empty';
    } else if (passwordRex.test(e.target.value)) {
      validate[name] = 'success';
    } else {
      validate[name] = 'danger';
    }
    const state = validate[name] === 'success';
    onValidation(name, state);
    this.setState({ validate });
  };

  render() {
    const { submit, name, placeholder } = this.props;
    const { validate, margin } = this.state;
    const password = validate[name];
    return (
      <FormGroup>
        <InputGroup className={margin}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-lock" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            name={name}
            placeholder={placeholder}
            onChange={this.onChange}
            type="Password"
            autoComplete="Password"
            valid={password === 'success'}
            invalid={password === 'danger' || password === 'empty' || submit}
          />
          <FormFeedback data-test="password_error" invalid="true">
            {password === 'empty' || submit
              ? 'Password cannot be empty'
              : 'Password must be at least 6 characters.'}
          </FormFeedback>
        </InputGroup>
        {/* {this.state.required.map((e) => {
          if (!e) {
            return <FormText key="notRequired">Mobile is optional</FormText>;
          }
          return null;
        })} */}
      </FormGroup>
    );
  }
}

export default UserPassword;

// eslint-disable-next-line import/no-cycle
import route from '../../layout/routes/Routes';

const { otherRoutes } = route;

export default {
  covidWorkflow: {
    user: {
      callerName: '',
      states: [],
      callerData: {},
    },
    token: '',
    formioToken: '',
    state: { id: '', name: '', status: false },
    languages: {
      data: [],
      status: 0,
    },
    states: {
      data: [],
      status: 0,
    },
    stats: {
      user: [],
    },
    allocatedBeneficiaries: {
      toDo: [],
      inProgress: [],
      status: false,
    },
    callAllocation: {
      status: false,
      message: '',
    },
    form: {},
    dimensions: { width: window.innerWidth, height: window.innerHeight },
    forgotPassword: {
      sendResetPasswordLinkStatus: { error: '', message: '', pending: false },
      resetPasswordStatus: { error: '', message: '', pending: false },
    },
    initiateCallStatus: '',
  },
  auth: {
    loginStatus: 0,
    loginErrMessage: '',
    signupStatus: 0,
    signupErrMessage: '',
    resetPasswordStatus: 0,
    resetPasswordError: '',
    sendOtpStatus: 0,
    resendOtpStatus: 0,
    verifyOtpStatus: 0,
    sendOtpErrMessage: '',
    resendOtpErrMessage: '',
    verifyOtpErrMessage: '',
    mobile: 0,
    userKey: {},
    token: '',
  },
  featureManagement: {
    featureList: {},
    userPermissions: [],
  },
  user: {
    updateStatus: 5896,
    info: {},
    state: { id: '', name: '' },
    states: [],
    features: [],
    sideBar: { items: [] },
    routes: [...otherRoutes],
    updateResponce: {},
  },
  formio: {
    createForm: { status: '', data: [] },
    formList: { status: '', data: [] },
    saveText: 'Create Form',
    message: '', // ERROR HANDLING
    messageType: 'danger', // ERROR HANDLING
    // formList: [], // PRODUCES ERROR
    newSubmission: { status: '', data: [] },
    syncapi: { status: '', data: [] },
    datadown: { status: '', data: [] },
    submissionList: [],
    form: '',
  },
  userMangement: {
    users: { status: '', data: [] },
    states: { status: '', data: [] },
    all_permissions: { status: '', data: [] },
    features: { status: '', data: [] },
    search: { status: '', data: [] },
    data: { status: '', data: [] },
    userPermissions: {},
  },
  dashboard: {
    nationalTotalCount: { status: '', data: [] },
    stateTotalCount: { status: '', data: {} },
    callerTotalCount: { status: '', data: {} },
  },
  bulkUpload: {
    bulkUpload: { status: '', data: {} },
  },
  exotel: {
    exotelCallCount: { status: '', data: [] },
    exotelNationalCount: { status: '', data: [] },
  },
  callerManagement: {
    callers: { status: '', data: [] },
    callerStatus: { status: '', data: [] },
    callerProfile: { status: '', data: [] },
  },
  reportGenerator: {
    reportResponse: { status: '', data: null },
  },
  taskManagement: {
    addTask: { status: '', data: [] },
    allTasks: { status: '', data: [] },
    addTaskStatus: { status: '', data: [] },
    allTaskStatus: { status: '', data: [] },
    allCycle: { status: '', data: [] },
    addMessage: { status: '', data: [] },
    allMessage: { status: '', data: [] },
    allCallers: { status: '', data: [] },
  },
  taskAssignment: {
    allTasks: { status: '', data: [] },
    allTasksNotDone: { status: '', data: [] },
    taskSearch: { status: '', data: [] },
    addTasks: { status: '', data: [] },
    singleForm: { status: '', data: [] },
    singlePForm: { status: '', data: [] },
    singleFormSub: { status: '', data: [] },
    singlePFormSub: { status: '', data: [] },
    formDets: { status: '', data: [] },
    updatetask: { status: '', data: [] },
    bulkassign: { status: '', data: [] },
    bulkundo: { status: '', data: [] },
    template: { status: '', data: [] },
    sendsms: { status: '', data: [] },
    myTasks: { status: '', data: [] },
    taskAssignmentState: { id: '', name: '' },
  },

  volunteerObj: {
    allVolunteer: { status: '', data: [] },
    volunteerSearch: { status: '', data: [] },
    addVolunteer: { status: '', data: [] },
    singleForm: { status: '', data: [] },
    singlePForm: { status: '', data: [] },
    singleFormSub: { status: '', data: [] },
    singlePFormSub: { status: '', data: [] },
    formDets: { status: '', data: [] },
    updateVolunteer: { status: '', data: [] },
    bulkassign: { status: '', data: [] },
    template: { status: '', data: [] },
    sendsms: { status: '', data: [] },
    myVolunteer: { status: '', data: [] },
    VolunteerState: { id: '', name: '' },
  },

  notification: {
    notifications: [],
  },
  bulkUploadSource: [
    { key: 'SRP', value: 'SRP' },
    { key: 'Exotel', value: 'Exotel' },
    { key: 'Servetel', value: 'servetel' },
    { key: 'Bulk Upload', value: 'bulkUpload' },
    { key: 'Bhumi App', value: 'bhumiApp' },
    { key: 'Ground Survey', value: 'Ground Survey' },
    { key: 'Bulk Import', value: 'Bulk Import' },
    { key: 'Knowlarity', value: 'knowlarity' },
    { key: 'Website Parent', value: 'websiteParent' },
    { key: 'Website Volunteer', value: 'websiteVolunteer' },
    { key: 'Parent Registration', value: 'parentRegistration' },
    { key: 'Volunteer Registration', value: 'volunteerRegistration' },
    { key: 'KA PMMVY 2018-19', value: 'KA PMMVY 2018-19' },
    { key: 'KA PMMVY 2019-20', value: 'KA PMMVY 2019-20' },
    { key: 'KA PMMVY 2020-21', value: 'KA PMMVY 2020-21' },
  ],
};

import React from 'react';
// import { Link, Redirect } from 'react-router-dom';
// import { connect } from 'react-redux';
// import Cookies from 'universal-cookie';
import { Button } from 'reactstrap';

// import { login } from '../../store/action/AuthAction';
// import { Username, UserPassword } from '../../components/index';

const ErrorWithProps = (prop) => {
  const { props } = prop;
  const { errMessage } = props;
  const { message, additionalInfo, type } = errMessage;

  let email = '';
  let mobile = '';
  let name = '';
  let password = '';
  if (type === 'RecordAlreadyExistsException') {
    if (additionalInfo.email) {
      email = 'Email id must be unique.';
    }
    if (additionalInfo.mobile) {
      mobile = 'Mobile number must be unique.';
    }
  }

  if (type === 'ValidationException') {
    additionalInfo.forEach((element) => {
      if (element.email) {
        email = 'Email id must be in correct format ie. example@gmail.com';
      }
      if (element.mobile) {
        mobile = 'Enter a valid 10 digit Mobile number.';
      }
      if (element.name) {
        const temp = element.name;
        name = temp;
      }
      if (element.password) {
        const temp = element.password;
        password = temp;
      }
    });
  }
  return (
    <div className="error">
      <Button
        color="link"
        className="btn-red-cross"
        onClick={() => document.location.reload()}
      >
        X
      </Button>
      {message}:
      <div>
        {email !== '' ? <li>{email}</li> : ''}
        {mobile !== '' ? <li>{mobile}</li> : ''}
        {name !== '' ? <li>{name}</li> : ''}
        {password !== '' ? <li>{password}</li> : ''}
      </div>
      Try again after refresh.
    </div>
  );
};

const ErrorWithoutProps = (props) => {
  const { message } = props;
  return (
    <div className="error">
      {message}
      <Button color="link" className="btn-red-cross">
        X
      </Button>
    </div>
  );
};

export { ErrorWithProps, ErrorWithoutProps };

import {
  CALLERMANAGEMENT_CALLERS,
  CALLERMANAGEMENT_CALLERS_STATUS,
  CALLERMANAGEMENT_USERSTATE,
  CALLERMANAGEMENT_USERSTATE_STATUS,
  CALLERMANAGEMENT_USERPROFILE,
  CALLERMANAGEMENT_USERPROFILE_STATUS,
} from '../action/Types';
import initialState from './InitialState';

export default function (state = initialState.callerManagement, action) {
  switch (action.type) {
    case CALLERMANAGEMENT_CALLERS_STATUS:
      return {
        ...state,
        callers: { ...state.callers, status: action.payload },
      };
    case CALLERMANAGEMENT_CALLERS:
      return {
        ...state,
        callers: { ...state.callers, data: action.payload },
      };
    case CALLERMANAGEMENT_USERSTATE_STATUS:
      return {
        ...state,
        callerStatus: { ...state.callerStatus, status: action.payload },
      };
    case CALLERMANAGEMENT_USERSTATE:
      return {
        ...state,
        callerStatus: { ...state.callerStatus, data: action.payload },
      };
    case CALLERMANAGEMENT_USERPROFILE_STATUS:
      return {
        ...state,
        callerProfile: { ...state.callerProfile, status: action.payload },
      };
    case CALLERMANAGEMENT_USERPROFILE:
      return {
        ...state,
        callerProfile: { ...state.callerProfile, data: action.payload },
      };
    default:
      return state;
  }
}

import React, { Component } from 'react';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

class ListGroups extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 1,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>List Group</strong>
                <div className="card-header-actions">
                  <a
                    href="https://reactstrap.github.io/components/listgroup/"
                    rel="noreferrer noopener"
                    target="_blank"
                    className="card-header-action"
                  >
                    <small className="text-muted">docs</small>
                  </a>
                </div>
              </CardHeader>
              <CardBody>
                <ListGroup>
                  <ListGroupItem>Cras justo odio</ListGroupItem>
                  <ListGroupItem>Dapibus ac facilisis in</ListGroupItem>
                  <ListGroupItem>Morbi leo risus</ListGroupItem>
                  <ListGroupItem>Porta ac consectetur ac</ListGroupItem>
                  <ListGroupItem>Vestibulum at eros</ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>List Group</strong>
                <small> tags</small>
              </CardHeader>
              <CardBody>
                <ListGroup>
                  <ListGroupItem className="justify-content-between">
                    Cras justo odio{' '}
                    <Badge className="float-right" pill>
                      14
                    </Badge>
                  </ListGroupItem>
                  <ListGroupItem className="justify-content-between">
                    Dapibus ac facilisis in{' '}
                    <Badge className="float-right" pill>
                      2
                    </Badge>
                  </ListGroupItem>
                  <ListGroupItem className="justify-content-between">
                    Morbi leo risus{' '}
                    <Badge className="float-right" pill color="warning">
                      1
                    </Badge>
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>List Group</strong>
                <small> disabled items</small>
              </CardHeader>
              <CardBody>
                <ListGroup>
                  <ListGroupItem disabled tag="a" href="#">
                    Cras justo odio
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#">
                    Dapibus ac facilisis in
                  </ListGroupItem>
                  <ListGroupItem disabled tag="a" href="#">
                    Morbi leo risus
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#">
                    Porta ac consectetur ac
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#">
                    Vestibulum at eros
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>List Group</strong>
                <small> contextual classes</small>
              </CardHeader>
              <CardBody>
                <ListGroup>
                  <ListGroupItem action color="success">
                    Cras justo odio
                  </ListGroupItem>
                  <ListGroupItem action color="info">
                    Dapibus ac facilisis in
                  </ListGroupItem>
                  <ListGroupItem action color="warning">
                    Morbi leo risus
                  </ListGroupItem>
                  <ListGroupItem action color="danger">
                    Porta ac consectetur ac
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>List Group</strong>
                <small> anchors</small>
              </CardHeader>
              <CardBody>
                <p>
                  Be sure to{' '}
                  <strong>
                    not use the standard <code>.btn</code> classes here
                  </strong>
                  .
                </p>
                <ListGroup>
                  <ListGroupItem active tag="a" href="#" action>
                    Cras justo odio
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#" action>
                    Dapibus ac facilisis in
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#" action>
                    Morbi leo risus
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#" action>
                    Porta ac consectetur ac
                  </ListGroupItem>
                  <ListGroupItem disabled tag="a" href="#" action>
                    Vestibulum at eros
                  </ListGroupItem>
                </ListGroup>
                <p />
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>List Group</strong>
                <small> buttons</small>
              </CardHeader>
              <CardBody>
                <ListGroup>
                  <ListGroupItem active tag="button" action>
                    Cras justo odio
                  </ListGroupItem>
                  <ListGroupItem tag="button" action>
                    Dapibus ac facilisis in
                  </ListGroupItem>
                  <ListGroupItem tag="button" action>
                    Morbi leo risus
                  </ListGroupItem>
                  <ListGroupItem tag="button" action>
                    Porta ac consectetur ac
                  </ListGroupItem>
                  <ListGroupItem disabled tag="button" action>
                    Vestibulum at eros
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>List Group</strong>
                <small> custom content</small>
              </CardHeader>
              <CardBody>
                <ListGroup>
                  <ListGroupItem active action>
                    <ListGroupItemHeading>
                      List group item heading
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      Donec id elit non mi porta gravida at eget metus. Maecenas
                      sed diam eget risus varius blandit.
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem action>
                    <ListGroupItemHeading>
                      List group item heading
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      Donec id elit non mi porta gravida at eget metus. Maecenas
                      sed diam eget risus varius blandit.
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem action>
                    <ListGroupItemHeading>
                      List group item heading
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      Donec id elit non mi porta gravida at eget metus. Maecenas
                      sed diam eget risus varius blandit.
                    </ListGroupItemText>
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>List Group</strong> <small>with TabPanes</small>
                <div className="card-header-actions">
                  <Badge>NEW</Badge>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="4">
                    <ListGroup id="list-tab" role="tablist">
                      <ListGroupItem
                        onClick={() => this.toggle(0)}
                        action
                        active={this.state.activeTab === 0}
                      >
                        Home
                      </ListGroupItem>
                      <ListGroupItem
                        onClick={() => this.toggle(1)}
                        action
                        active={this.state.activeTab === 1}
                      >
                        Profile
                      </ListGroupItem>
                      <ListGroupItem
                        onClick={() => this.toggle(2)}
                        action
                        active={this.state.activeTab === 2}
                      >
                        Messages
                      </ListGroupItem>
                      <ListGroupItem
                        onClick={() => this.toggle(3)}
                        action
                        active={this.state.activeTab === 3}
                      >
                        Settings
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                  <Col xs="8">
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId={0}>
                        <p>
                          Velit aute mollit ipsum ad dolor consectetur nulla
                          officia culpa adipisicing exercitation fugiat tempor.
                          Voluptate deserunt sit sunt nisi aliqua fugiat
                          proident ea ut. Mollit voluptate reprehenderit
                          occaecat nisi ad non minim tempor sunt voluptate
                          consectetur exercitation id ut nulla. Ea et fugiat
                          aliquip nostrud sunt incididunt consectetur culpa
                          aliquip eiusmod dolor. Anim ad Lorem aliqua in
                          cupidatat nisi enim eu nostrud do aliquip veniam
                          minim.
                        </p>
                      </TabPane>
                      <TabPane tabId={1}>
                        <p>
                          Cupidatat quis ad sint excepteur laborum in esse qui.
                          Et excepteur consectetur ex nisi eu do cillum ad
                          laborum. Mollit et eu officia dolore sunt Lorem culpa
                          qui commodo velit ex amet id ex. Officia anim
                          incididunt laboris deserunt anim aute dolor incididunt
                          veniam aute dolore do exercitation. Dolor nisi culpa
                          ex ad irure in elit eu dolore. Ad laboris ipsum
                          reprehenderit irure non commodo enim culpa commodo
                          veniam incididunt veniam ad.
                        </p>
                      </TabPane>
                      <TabPane tabId={2}>
                        <p>
                          Ut ut do pariatur aliquip aliqua aliquip exercitation
                          do nostrud commodo reprehenderit aute ipsum voluptate.
                          Irure Lorem et laboris nostrud amet cupidatat
                          cupidatat anim do ut velit mollit consequat enim
                          tempor. Consectetur est minim nostrud nostrud
                          consectetur irure labore voluptate irure. Ipsum id
                          Lorem sit sint voluptate est pariatur eu ad cupidatat
                          et deserunt culpa sit eiusmod deserunt. Consectetur et
                          fugiat anim do eiusmod aliquip nulla laborum elit
                          adipisicing pariatur cillum.
                        </p>
                      </TabPane>
                      <TabPane tabId={3}>
                        <p>
                          Irure enim occaecat labore sit qui aliquip
                          reprehenderit amet velit. Deserunt ullamco ex elit
                          nostrud ut dolore nisi officia magna sit occaecat
                          laboris sunt dolor. Nisi eu minim cillum occaecat aute
                          est cupidatat aliqua labore aute occaecat ea aliquip
                          sunt amet. Aute mollit dolor ut exercitation irure
                          commodo non amet consectetur quis amet culpa. Quis
                          ullamco nisi amet qui aute irure eu. Magna labore
                          dolor quis ex labore id nostrud deserunt dolor eiusmod
                          eu pariatur culpa mollit in irure.
                        </p>
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ListGroups;

import React, { Component } from 'react';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  FormGroup,
} from 'reactstrap';

class Password extends Component {
  state = { validate: {} };

  onChange = async (e) => {
    const { onChange, onValidation } = this.props;
    const { repassword, password } = this.state;
    e.persist();
    onChange(e);
    const { validate } = this.state;
    if (e.target.name === 'password') {
      await this.validate(e);
      if (repassword) {
        if (password !== repassword) {
          validate.repassword = 'danger';
        } else {
          validate.repassword = 'success';
        }
        const state = validate.repassword === 'success';
        onValidation('repassword', state);
        await this.setState({ validate });
      }
    } else {
      await this.rePasswordCheck(e);
    }
  };

  validate = (e) => {
    const passwordRex = /^.{6,}$/;
    const { validate } = this.state;
    const { onValidation } = this.props;
    const { name } = e.target;
    if (!e.target.value) {
      validate[name] = 'empty';
    } else if (passwordRex.test(e.target.value)) {
      validate[name] = 'success';
    } else {
      validate[name] = 'danger';
    }
    const state = validate[name] === 'success';
    onValidation(name, state);
    this.setState({ validate, password: e.target.value });
  };

  rePasswordCheck = (e) => {
    const { password, validate } = this.state;
    const { onValidation } = this.props;
    const { name } = e.target;
    if (!e.target.value) {
      validate[name] = 'empty';
    } else if (validate.password === 'success') {
      if (e.target.value === password) {
        validate[name] = 'success';
      } else {
        validate[name] = 'mismatch';
      }
    } else {
      validate[name] = 'danger';
    }
    const state = validate[name] === 'success';
    onValidation(name, state);
    this.setState({ validate, repassword: e.target.value });
  };

  render() {
    const { submit, placeholder } = this.props;
    const { validate } = this.state;
    const { password, repassword } = validate;
    return (
      <React.Fragment>
        <FormGroup>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-lock" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              name="password"
              placeholder={placeholder || 'Password'}
              onChange={this.onChange}
              type="password"
              autoComplete="new-password"
              valid={password === 'success'}
              invalid={password === 'danger' || password === 'empty' || submit}
            />
            <FormFeedback data-test="password_error" invalid="true">
              {password === 'empty' || submit
                ? 'Password cannot be empty'
                : 'Password must be at least 6 characters.'}
            </FormFeedback>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-lock" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              name="repassword"
              placeholder="Confirm Password"
              onChange={this.onChange}
              type="password"
              autoComplete="new-password"
              valid={repassword === 'success'}
              invalid={
                repassword === 'danger'
                || repassword === 'empty'
                || repassword === 'mismatch'
                || submit
              }
            />
            <FormFeedback data-test="repassword_error" invalid="true">
              {// eslint-disable-next-line no-nested-ternary
              repassword === 'empty' || submit
                ? 'Confirm Password cannot be empty.'
                : password === 'danger'
                ? 'Password must be at least 6 characters.'
                : 'Your Password and Confirmed Password does not match.'}
            </FormFeedback>
          </InputGroup>
        </FormGroup>
      </React.Fragment>
    );
  }
}

export default Password;

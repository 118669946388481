import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import actions from '../store/actions';

const cookies = new Cookies();

class Statistics extends Component {
  componentDidMount() {
    const { getUserStats, userEmail } = this.props;
    getUserStats(userEmail || cookies.get('covidHelplineCaller').callerEmail);
  }

  render() {
    const { userStats, userEmail } = this.props;
    return (
      <div className="pt-3 px-3">
        {userStats.map(({ stateName, status: { total, ...status } }) => (
          <div className="card" key={`${userEmail}_${stateName}`}>
            <div className="card-header d-flex justify-content-between">
              <span>{stateName}</span>
              <span>Total: {total}</span>

            </div>
            <div className="card-body">
              <ul>
                {Object.entries(status).map(([statusName, count]) => (
                  <li key={`${userEmail}_${stateName}_${statusName}`}>
                    {statusName}: {count}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  userStats: state.covidWorkflow.stats.user,
  userEmail: state.covidWorkflow.user.callerEmail,
});

export default connect(mapStateToProps, {
  getUserStats: actions.getUserStats,
})(Statistics);
